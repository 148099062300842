import React, { useContext, useState } from "react";
import { LoginWrapper } from "./LoginWrapper";
import { formValidator } from "utils/FormValidator/FormValidator";
import blackMadam from "assets/images/black-madam.jpg";
import { ReactComponent as Logo } from "assets/images/icons/bluintouch-logo-dark.svg";
import { ReactComponent as Mtn } from "assets/images/icons/MTN_Logo.svg";
import { ReactComponent as Airtel } from "assets/images/icons/airtel-logo.svg";
import { ReactComponent as Zenith } from "assets/images/icons/zenith-logo.svg";
import { ReactComponent as Ecobank } from "assets/images/icons/ecobank.svg";
import { ReactComponent as LeadRemit } from "assets/images/icons/lead-remit.svg";
import { useHistory, Link } from "react-router-dom";
import CustomInputBox from "utils/inputBox/CustomInputBox/CustomInputBox";
import CustomPassword from "utils/inputBox/CustomInputBox/CustomPassword";
import API from "api";
import { useMutation } from "react-query";
import { loginUser } from "api/auth";
import Alert from "utils/Alert/Alert";
import CustomSpinner from "utils/Spinner/Spinner";
import Cookies from "js-cookie";
import {
  CONTACT_US_LINK,
  PRIVACY_POLICY_LINK,
  TOKEN_KEY,
} from "utils/helpers/constants";
import { AuthContext } from "utils/context/AuthContext";
import VerifyOTPModal from "./modal/VerifyOTPModal";
import VerifyAppAuthModal from "./modal/VerifyAppAuthModal";

const Login = () => {
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState();
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [showAppModal, setShowAppModal] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const { refetch } = useContext(AuthContext);
  const [userId, setUserid] = useState("");

  const toggle = () => {
    setShowModal((prev) => !prev);
  };
  const toggleAppModal = () => {
    setShowAppModal((prev) => !prev);
  };
  const handleChange = ({ target: { name, value } }) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  function handleSubmit(e) {
    e.preventDefault();
    const { formErrors, isValid } = formValidator({ email: formValues.email });
    isValid ? mutate() : setFormErrors(formErrors);
  }

  const { mutate, isLoading } = useMutation(
    async () => {
      setErrorMsg();
      const payload = {
        ...formValues,
      };
      const response = await API.post(loginUser, payload);
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 200 && data?.data?.token) {
          const { token } = data?.data;
          Cookies.set(TOKEN_KEY, token, { expires: 1 });
          history.push("/dashboard");
          refetch();
        } else if (status === 200 && data?.data?.authenticatorApp) {
          setUserid(data?.data?.userId);
          toggleAppModal();
        } else {
          toggle();
        }
      },
      onError: (error) => {
        setErrorMsg(error?.data?.data?.error);
      },
    }
  );

  return (
    <LoginWrapper className=" d-flex">
      <div className="left-div">
        <div className="auth-nav flex__between">
          <Logo className="pointer" />
          <div className="auth-nav-links d-flex">
            <a
              href={PRIVACY_POLICY_LINK}
              to={PRIVACY_POLICY_LINK}
              target="_blank"
              rel="noreferrer"
              className="mx-4"
            >
              Privacy Policy
            </a>
            <a
              to={CONTACT_US_LINK}
              target="_blank"
              rel="noreferrer"
              href={CONTACT_US_LINK}
            >
              Contact Us
            </a>
          </div>
        </div>
        <div className="login-form">
          <div className="px-5 py-4">
            {errorMsg && (
              <Alert message={errorMsg} onClose={() => setErrorMsg()} />
            )}
          </div>
          <h2 className="m-0 p-0">Welcome Partner!</h2>
          <p className="m-0 text--md">Log in to continue</p>
          <form onSubmit={handleSubmit}>
            <CustomInputBox
              placeholder="yemi@blusalt.net"
              error={formErrors?.email}
              name="email"
              onChange={handleChange}
              value={formValues?.email}
            />
            <CustomPassword
              placeholder="Password"
              error={formErrors?.password}
              name="password"
              onChange={handleChange}
              value={formValues?.password}
            />
            <button className="btn btn--primary btn--lg" type="submit">
              {isLoading ? <CustomSpinner /> : "Login"}
            </button>
            <p className="mt-5 heading-3">
              Forgot Password?{" "}
              <Link className="text--secondary" to="/forgot-password">
                Reset
              </Link>
            </p>
          </form>
        </div>

        <div className="partners-div p-5 rounded flex__between">
          <Mtn />
          <Airtel className="mx-2" />
          <Zenith className="mx-3" />
          <Ecobank className="mx-3" />
          <LeadRemit />
        </div>
        <div>
          <p className="mt-5">© Blusalt Financial Service Ltd. </p>
        </div>
      </div>
      <div className="right-img-div">
        <img src={blackMadam} alt="black madam" />
      </div>
      {showModal && <VerifyOTPModal toggle={toggle} />}
      {showAppModal && (
        <VerifyAppAuthModal toggle={toggleAppModal} userId={userId} />
      )}
    </LoginWrapper>
  );
};

export default Login;
