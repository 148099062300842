import TransactionRecieptModal from "components/Dashboard/Reports/modal/TransactionRecieptModal";
import { useState } from "react";
import { toCurrency } from "utils/helpers";
import UserInfoPill from "utils/pills/UserInfoPill/UserInfoPill";
import SectionWrapper from "utils/section/SectionWrapper/SectionWrapper";
import Table from "utils/Table/Table";
import WalletDetailsLeftChart from "../charts/WalletDetailsLeftChart/WalletDetailsLeftChart";
import WalletDetailsRIghtChart from "../charts/WalletDetailsRIghtChart/WalletDetailsRIghtChart";
import { walletTransTableData, walletTransTableHeadings } from "../data";
import WalletFilter from "../filters/WalletFilter";
import FundWalletModal from "../modals/FundWalletModal";

const WalletDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const [showTransModal, setShowTransModal] = useState(false);
  const toggle = () => {
    setShowModal((prev) => !prev);
  };
  const toggleTrans = () => {
    setShowTransModal((prev) => !prev);
  };
  const currency = "NGN";
  const getBalanceData = () =>
    walletTransTableData?.map((walletTrans) => ({
      name: (
        <UserInfoPill
          name={walletTrans?.description}
          company={walletTrans?.service}
        />
      ),
      id: walletTrans?.id,
      amount: `${currency} ${toCurrency(walletTrans?.amount)}`,
      createdAt: walletTrans?.createdAt,
    }));

  const handleRowClick = (id, rowValues) => {
    console.log("data", rowValues);
    toggleTrans();
  };
  return (
    <>
      <SectionWrapper
        routeName="Wallet Details"
        goBackRoute={{ name: "Wallets", to: "/wallets" }}
        headerChildren={
          <button className="btn btn--primary" onClick={toggle}>
            Fund Merchant Wallet
          </button>
        }
      >
        <div className="row">
          <WalletDetailsLeftChart />
          <WalletDetailsRIghtChart />
        </div>
        <div className="p-4 bg-white rounded-card">
          <WalletFilter />
          <Table
            title="Merchant Wallet Details"
            data={getBalanceData()}
            headings={walletTransTableHeadings}
            rowClick={handleRowClick}
          />
        </div>
        {showTransModal && (
          <TransactionRecieptModal  toggle={toggleTrans} />
        )}
        {showModal && <FundWalletModal toggle={toggle} />}
      </SectionWrapper>
    </>
  );
};

export default WalletDetails;
