import _ from "lodash";
import { Scrollbars } from "react-custom-scrollbars-2";

import TableBody from "./TableBody";
import TableHead from "./TableHead";
import TableFoot from "./TableFoot";

const Table = ({
  headings,
  title,
  data = [],
  footings,
  indexRow,
  cls,
  grayRunning,
  rowClick,
  autoHeightMax = 616,
}) => {
  const isGrayRunning = grayRunning
    ? " table__head--gray table__foot--gray"
    : "";
  const sortedData = _.sortBy(data, (val) => (indexRow ? val[indexRow] : ""));

  const groupedData = _.groupBy(sortedData, (val) => {
    return indexRow ? val[indexRow]?.charAt(0) : "";
  });

  const indexedData = _.map(groupedData, (_, key) => (
    <span onClick={() => scroll(key)}>{key}</span>
  ));

  let tableData = _.flatMapDeep(
    _.map(groupedData, (value, key) =>
      value.map((val) => ({ value: val, id: key }))
    )
  );

  tableData = indexRow ? tableData : data;

  const scroll = (id) => {
    const section = document.querySelector(`#${id}`);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div className="mt-4">
      <h3 className="text--grey fw-600">{title}</h3>
      <div className={`table__wrapper${isGrayRunning} ${cls}`}>
        {indexRow && (
          <div className="table__index">
            <Scrollbars autoHide autoHeight autoHeightMax={550}>
              {indexedData?.map((val) => (
                <p key={val} className="text-capitalize text--gray pointer">
                  {val}
                </p>
              ))}
            </Scrollbars>
          </div>
        )}

        <Scrollbars autoHide autoHeight autoHeightMax={autoHeightMax}>
          <table className="table">
            {headings && <TableHead headings={headings} />}
            <TableBody data={tableData} rowClick={rowClick} />
            {footings && <TableFoot footings={footings} rowClick={() => {}} />}
          </table>
        </Scrollbars>
      </div>
      {/* <div className="text-center pt-5 heading-3">
				Showing 10 items out of {data.length}
			</div> */}
    </div>
  );
};

export default Table;
