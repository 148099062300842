import { createPortal } from "react-dom";
import { SketchPicker } from "react-color";
import { createPopper } from "@popperjs/core";
import { useLayoutEffect, useRef, useState } from "react";

import { ReactComponent as InfoCircleError } from "assets/images/icons/info-circle-error.svg";
import { useOnClickOutside } from "hooks/useOnClickOutside";

export function CustomColorPicker({
  id,
  name,
  label,
  labelIcon,
  placeholder,
  value = "",
  error,
  hide,
  icon,
  iconRight,
  secondary,
  inputCls = "",
  infoText,
  inputLeft,
  onChange,
  onFocus,
  required,
  length,
  question,
  cls = "",
  disabled,
  ...rest
}) {
  const isError = error ? " error" : "";
  const isRequired = required ? " required" : "";
  const isDisabled = disabled ? " disabled" : "";
  const inputWidth = icon || inputLeft ? "" : " w-100";
  const isSecondary = secondary ? " secondary-input" : "";
  const inputWithIconCls = icon && iconRight ? "rounded-0" : "";
  const [displayPicker, setDisplayPicker] = useState(false);

  const selectRef = useRef();
  const pickerRef = useRef();

  const popperInstance = useRef();

  useOnClickOutside(pickerRef, (e) => {
    if (e.target.id === (id || name) || e.target.closest(`#${id || name}`)) {
      return;
    }
    setDisplayPicker(false);
  });

  useLayoutEffect(() => {
    popperInstance.current = createPopper(
      selectRef.current,
      pickerRef.current,
      {
        placement: "bottom-start",
      }
    );
  }, []);

  useLayoutEffect(() => {
    popperInstance?.current?.update();
  }, [displayPicker]);

  const handleChange = (color) => {
    onChange(color);
  };

  return (
    <div className={`form-group ${cls}${isSecondary}`}>
      {label && (
        <label
          htmlFor={name}
          className={`form-group-label d-flex${isRequired}`}
        >
          {label}
          {question && <div className="question">?</div>}
          {labelIcon && <div className="ms-2 pointer">{labelIcon}</div>}
        </label>
      )}
      <div
        id={id || name}
        ref={selectRef}
        className={`pointer flex__start input-wrapper ${isDisabled}${isError} ${inputCls}`}
      >
        <div className="position-relative">
          <div
            onClick={() => setDisplayPicker((prev) => !prev)}
            className="color-holder"
            style={{
              backgroundColor: value,
            }}
          ></div>
        </div>
        {document &&
          createPortal(
            <div
              ref={pickerRef}
              className={`${displayPicker ? "" : "d-none"}`}
              style={{ zIndex: 999 }}
            >
              <SketchPicker
                color={value?.rgb || value}
                onChange={handleChange}
              />
            </div>,
            document.body
          )}
        {!hide && (
          <input
            onClick={() => setDisplayPicker((prev) => !prev)}
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            className={`${inputWithIconCls}${inputWidth}`}
            id={`${id || name}-input`}
            name={name}
            placeholder={placeholder}
            value={value?.hex}
            readOnly={true}
            autoComplete="off"
            disabled={disabled}
            step="any"
            {...rest}
          />
        )}
        {iconRight}
      </div>
      {infoText && <div className="info-text">{infoText}</div>}
      {typeof error === "string" && error && (
        <small className="input-text-error">
          <InfoCircleError className="me-2" />
          {error}
        </small>
      )}
    </div>
  );
}
