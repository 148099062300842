import { useState } from 'react';
import { Menu, MenuItem } from "@material-ui/core";

// import { colors } from "@components/Layout/extra";
// import { Dropdown_Menu_Layout } from './DropdownSC';

const Dropdown = ({ action = () => {}, cls, menuCls, icon, id="dropdown", options, Header, footer }) => {
    const [ anchorEl, setAnchorEl] = useState(null)
    const handleOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    return (
        <div className='w-100'>
            <div className={`pointer ps-2 ${cls}`} onClick={handleOpen}>{icon}</div>
            <Menu 
                id={id} 
                anchorEl={anchorEl} 
                keepMounted 
                open={Boolean(anchorEl)} 
                onClose={handleClose} 
                className={`dropdown ${menuCls}`} 
                disableScrollLock
            >
                {/* <Dropdown_Menu_Layout colors={colors}> */}
                    {Header && <Header cls="px-4 py-3 border--bottom-gray" btnClick={handleClose} />}

                    <OptionsMenu 
                        action={action}
                        options={options}
                        handleClose={handleClose}
                    />

                    {footer && (
                        <div className="text--normal border--top-gray">
                            {Array.isArray(footer) && (
                                <OptionsMenu 
                                    action={action} 
                                    options={footer} 
                                    handleClose={handleClose} 
                                />
                            )}

                            {!Array.isArray(footer) && <>{footer}</>}
                        </div>
                    )}
                {/* </Dropdown_Menu_Layout> */}
            </Menu>
        </div>
    );
};

const OptionsMenu = ({ action, handleClose, options }) => (
    <>
        {options?.map((option,index) => (
            <MenuItem
                key={index}
                onClick={() => {
                    handleClose();
                    action(index)
                }}
                className="text--normal pointer"
            >
                {option?.label ? option?.label : option}
            </MenuItem>
        ))}
    </>
) 

export default Dropdown;