import { toast } from "react-toastify";
import { SUCCESS } from "./constants";

export const toastHandler = (message, type, position = "top-right") => {
	const options = {
		position: position,
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	};
	toast.dismiss();

	type === SUCCESS
		? toast.success(message, options)
		: toast.error(message, options);
};
