import AlertIcon from "assets/images/icons/alert.svg";

const FailedState = ({
  cls,
  errorMsg,
  title,
  onRetry,
  btnText = "Retry",
  showBtn = true,
}) => (
  <div className={`text-center mt-3 ${cls}`}>
    <AlertIcon />

    {title && <p className="heading-1 mt-5">{title}</p>}

    {errorMsg && <h4 className="my-4">{errorMsg}</h4>}

    <div className="flex__center">
      {showBtn && (
        <button className="btn btn--lg btn--secondary" onClick={onRetry}>
          {btnText}
        </button>
      )}
    </div>
  </div>
);

export default FailedState;
