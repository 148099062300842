import { LAYOUT } from "components/constants";
import styled from "styled-components";

export const DashboardWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  .main {
    padding-top: 130px !important;
    width: calc(100% - 273px);
    min-height: calc(100vh - 80px);
    margin-left: 273px;
    background: var(--background-gray);
  }
  @media (max-width: ${LAYOUT.xxLg}) {
    .main {
      padding-top: 110px !important;
      width: 100vw;
      margin-left: 0;
    }
  }
`;