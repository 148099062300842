import React from "react";
import Navlink from "../Navlink/Navlink";
import { ReactComponent as HomeIcon } from "assets/images/icons/home-2.svg";
import { ReactComponent as RequestIcon } from "assets/images/icons/people.svg";
import { ReactComponent as KycIcon } from "assets/images/icons/receipt-item.svg";
import { ReactComponent as ReportIcon } from "assets/images/icons/percentage-square.svg";
import { ReactComponent as AuditIcon } from "assets/images/icons/wallet.svg";
import { ReactComponent as UsersIcon } from "assets/images/icons/document.svg";
import { SideNavWrapper } from "./SideNavWrapper";

const SideNav = () => {
  const sidebarLinks = [
    {
      link: "/dashboard",
      name: "Dashboard",
      icon: HomeIcon,
    },
    {
      link: "/merchants",
      name: "Merchants",
      icon: RequestIcon,
    },
    {
      link: "/prices",
      name: "Prices",
      icon: KycIcon,
    },
    {
      link: "/commissions",
      name: "Commissions",
      icon: ReportIcon,
    },
    {
      link: "/wallets",
      name: "Wallets",
      icon: AuditIcon,
    },
    {
      link: "/reports/audit-trail",
      name: "Reports",
      icon: UsersIcon,
    },
  ];
  return (
    <SideNavWrapper>
      <ul>
        {sidebarLinks.map((link, i) => (
          <Navlink key={i} link={link} />
        ))}
      </ul>
    </SideNavWrapper>
  );
};

export default SideNav;
