import { useEffect, useState } from "react";

import { ReactComponent as EllipseIcon } from "assets/images/icons/ellipse.svg";

import FilterWrapper from "utils/FilterWrapper/FilterWrapper";
import CustomCheckBox from "utils/CustomCheckBox/CustomCheckBox";
import ExportAndFilter from "components/Reuseables/ExportAndFilter/ExportAndFilter";
import { merchantsFiltersDefaultValues, transactionsReportHeadings } from "../data";

const TransactionsFilter = ({ data, filters, handleFilters, clearFilters }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    ...merchantsFiltersDefaultValues,
  });
  const [isApplied, setIsApplied] = useState(false);
  useEffect(() => {
    if (filters) {
      setFormValues(filters);
      setIsApplied(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (name, value) => {
    if (formValues[name]) {
      setFormValues((prev) => ({
        ...prev,
        [name]: null,
      }));

      return;
    }

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearFilter = () => {
    setFormValues({ ...merchantsFiltersDefaultValues });
    clearFilters();
    setIsApplied(false);
  };

  const handleSubmit = () => {
    handleFilters({
      data: { ...formValues, interval: formValues?.interval?.value },
    });
    setIsApplied(true);
  };

  return (
    <div className="filters__container">
      <ExportAndFilter
        data={{
          data,
          filters,
          tableHeadings: transactionsReportHeadings,
          subTitle: "Download Transactions  Report",
        }}
        handleFilters={handleFilters}
        clearFilters={clearFilters}
        hideExportBtn={data?.total === 0}
        onClick={() => setIsOpen((prev) => !prev)}
      />
      {isOpen && (
        <FilterWrapper
          handleFilter={handleSubmit}
          clearFilter={clearFilter}
          isApplied={isApplied}
          isFilter
        >
          <div className="form-group">
            <label className="form-group-label mb-2">Filter Status</label>
            <div className="col-12">
              <CustomCheckBox
                checked={formValues["status[0]"] === "successful"}
                label={
                  <>
                    <EllipseIcon className="status--success" />
                    <span>Success</span>
                  </>
                }
                name="success"
                onClick={() => handleSelect("status[0]", "successful")}
              />

              <CustomCheckBox
                checked={formValues["status[1]"] === "pending"}
                label={
                  <>
                    <EllipseIcon className="status--pending" />
                    <span>Pending</span>
                  </>
                }
                name="pending"
                onClick={() => handleSelect("status[1]", "pending")}
              />

              <CustomCheckBox
                checked={formValues["status[2]"] === "failed"}
                label={
                  <>
                    <EllipseIcon className="status--failed" />
                    <span>Failed</span>
                  </>
                }
                name="failed"
                onClick={() => handleSelect("status[2]", "failed")}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-group-label mb-2">Filter Type</label>
            <div className="col-12">
              <CustomCheckBox
                checked={formValues["action[0]"] === "credit"}
                label={
                  <>
                    <EllipseIcon className="status--success" />
                    <span>Credit</span>
                  </>
                }
                name="credit"
                onClick={() => handleSelect("action[0]", "credit")}
              />

              <CustomCheckBox
                checked={formValues["action[1]"] === "debit"}
                label={
                  <>
                    <EllipseIcon className="status--failed" />
                    <span>Debit</span>
                  </>
                }
                name="debit"
                onClick={() => handleSelect("action[1]", "debit")}
              />
            </div>
          </div>
        </FilterWrapper>
      )}
    </div>
  );
};

export default TransactionsFilter;
