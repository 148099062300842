import { useEffect, useState } from "react";
import CustomOTPInput from "utils/inputBox/CustomOTPInput/CustomOTPInput";
import { CENTER, ERROR } from "utils/helpers/constants";
import Modal from "utils/Modal/Modal";
import { ReactComponent as SecuritySafe } from "assets/images/icons/security-safe.svg";
import { useMutation } from "react-query";
import API from "api";
import { toastHandler } from "utils/helpers/toast";
import { enableAppAuth, verifyAppAuth } from "api/auth";
import CustomSpinner from "utils/Spinner/Spinner";

function AppAuthenticatorModal({ toggle, title, user, refetch }) {
  const [qrCode, setQrcode] = useState(null);
  const [step, setStep] = useState(0);
  const [OTP, setOTP] = useState();
  function gotoNext() {
    mutate();
  }

  useEffect(() => {
    (async function () {
      try {
        const response = await API.post(enableAppAuth, {
          set2FA: true,
        });

        setQrcode(response.data.data.qrCode);
      } catch (error) {
        toggle()
        toastHandler(error.data.data.error, ERROR);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const { mutate, isLoading } = useMutation(
    async () => {
      const response = await API.post(verifyAppAuth, {
        token: OTP,
        userId: user.id,
      });
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 200 && data?.success) {
          refetch();
          setStep(step + 1);
        }
      },
      onError: (error) => {
        toastHandler(error.data.data.error, ERROR);
      },
    }
  );
  return (
    <Modal
      title={title}
      subtitle="Permission Request"
      toggle={toggle}
      position={CENTER}
    >
      <div className="px-4 py-50 mx-4">
        {step === 0 && (
          <>
            {qrCode ? (
              <>
                <h3 className="text-center mt-5 fw-600 ">
                  1: Scan the QR CODE
                </h3>
                <div className="d-flex flex__center">
                  <img src={qrCode} alt="qrCode" />
                </div>
                <h3 className="text-center mt-5 fw-600 ">2: Enter OTP</h3>
                <h5 className="text-center mb-5 fw-600 text--grey">
                  Enter the 6-digit code sent to your Authenticator App
                </h5>
                <CustomOTPInput
                  name="otp"
                  onChange={(value) => setOTP(value)}
                  value={OTP}
                  separator="-"
                />
                <button className="btn btn--primary px-4" onClick={gotoNext}>
                  {isLoading ? <CustomSpinner /> : "Next"}
                </button>
              </>
            ) : (
              <CustomSpinner />
            )}
          </>
        )}
        {step === 1 && (
          <>
            <div className="flex__center">
              <SecuritySafe />
            </div>
            <h3 className="text-center mt-5 fw-600 ">
              App Authentication Enabled
            </h3>
            <h5 className="text-center mb-5 fw-600 text--grey">
              Your account is secured
            </h5>
            <button className="btn btn--primary px-4" onClick={toggle}>
              Got it
            </button>
          </>
        )}
      </div>
    </Modal>
  );
}

export default AppAuthenticatorModal;
