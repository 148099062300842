import styled from "styled-components";

export const StyledDateSelectWrapper = styled.div`
  .MuiSelect-outlined {
  }
  .MuiOutlinedInput-root {
    background: var(--secondary-accent-zeta);

    border-radius: 20px;
    padding: 2px 7px;
    width: 100px;
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
`;
