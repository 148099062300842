import BrailleDropDown from "utils/BrailleDropDown/BrailleDropDown";
import { DELETE, BLOCK, CHANGE, RESEND } from "../../data";
import { CustomMenuItem } from "utils/BrailleDropDown/dropdown.styled";
import { ACTIVE, PENDING } from "utils/helpers/constants";

const TeamActionButtons = ({ data, setSelected }) => {
  return (
    <BrailleDropDown
      data={data}
      setSelected={setSelected}
      component={DropButtons}
    />
  );
};

const DropButtons = ({ data, setSelected, handleClose }) => {
  const { status } = data || {};
  const handleMenuClick = (status) => {
    setSelected(data, status);
    handleClose();
  };

  //   const copy = () => {
  //     navigator.clipboard.writeText(invoice_link);
  //     toastHandler("Invoice link copied successfully!", SUCCESS);
  //     handleClose();
  //   };
  //   trigger redeployment

  return (
    <>
      {status?.toUpperCase() === PENDING && (
        <>
          <CustomMenuItem onClick={() => handleMenuClick(RESEND)}>
            Resend Invitation
          </CustomMenuItem>
        </>
      )}
      {status?.toUpperCase() === ACTIVE && (
        <>
          <CustomMenuItem onClick={() => handleMenuClick(BLOCK)}>
            Block Member Access
          </CustomMenuItem>
          <CustomMenuItem onClick={() => handleMenuClick(CHANGE)}>
            Change Role
          </CustomMenuItem>
        </>
      )}
      <CustomMenuItem onClick={() => handleMenuClick(DELETE)}>
        Delete Member
      </CustomMenuItem>
    </>
  );
};

export default TeamActionButtons;
