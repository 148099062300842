import React from "react";

const TableCell = ({ cellValues, tag }) => 
  React.createElement(
  	tag,
  	{ className: `text-nowrap${tag === "th" ? " text-capitalize" : ""}` },
  	cellValues
  );

export default TableCell;
