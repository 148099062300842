
import logo from "assets/images/icons/bluintouch-logo.svg";
import SideNav from "../SideNav/SideNav";
import LowerSideNav from "../LowerSideNav/LowerSideNav";
import { SidebarWrapper } from "./SidebarWrapper";

const Sidebar = (props) => {
  return (
    <SidebarWrapper open={props.open}>
      <div className="top">
        <div className="logo">
          <img src={logo} alt="app-logo" />
        </div>
        <SideNav />
      </div>
      <div className="bottom">
        <LowerSideNav />
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;