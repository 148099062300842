import { UserInfoPillWrapper } from "./UserInfoWrapper";
import { ReactComponent as Avatar } from "assets/images/icons/fine-boy.svg";

function UserInfoPill({name, company}) {
  return (
    <UserInfoPillWrapper>
      <Avatar />
      <div>
        <span className="name">
            {name}
        </span>
        <br />
        <span className="company">
            {company}
        </span>
      </div>
    </UserInfoPillWrapper>
  );
}

export default UserInfoPill;
