import { Country } from "country-state-city";
import CustomSelect from "../CustomSelect/CustomSelect";

const CountrySelect = ({
	label,
	LabelIcon,
	name,
	value,
	error,
	isMulti,
	icon,
	inputCls,
	inputLeft,
	labelChildren,
	placeholder = "",
	secondary,
	required,
	disabled,
	getOptionLabel,
	getOptionValue,
	formGroupCls,
	onChange,
	...rest
}) => {
	const countries = Country.getAllCountries();

	const parsedCountries = countries.map((country) => ({
		label: country?.name,
		value: country?.isoCode,
		...country,
	}));

	return (
		<CustomSelect
			label={label}
			LabelIcon={LabelIcon}
			name={name}
			value={value}
			error={error}
			isMulti={isMulti}
			icon={icon}
			inputCls={inputCls}
			inputLeft={inputLeft}
			labelChildren={labelChildren}
			options={parsedCountries}
			placeholder={placeholder}
			secondary={secondary}
			required={required}
			disabled={disabled}
			getOptionLabel={getOptionLabel}
			getOptionValue={getOptionValue}
			formGroupCls={formGroupCls}
			onChange={onChange}
			{...rest}
		/>
	);
};

export default CountrySelect;
