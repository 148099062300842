// HEADINGS

export const merchantsTableHeadings = [
  "Name",
  "Email",
  "Country",
  "Phone",
  "Created At",
];
export const merchantsReportHeadings = {
  "user.firstName": "First Name",
  "user.lastName": "Last Name",
  "user.email": "Email",
  "user.country": "Country",
  "user.phoneNumber": "Phone",
  "user.createdAt": "created At",
};

export const merchantsFiltersDefaultValues = {
  "status[0]": null,
  "status[1]": null,
  "status[2]": null,
  "action[0]": null,
  "action[1]": null,
};

export const transactionsTableHeadings = [
  "User",
  "Transaction ID",
  "Amount",
  "Type",
  "Balance",
  "Status",
  "Created At",
];
export const transactionsReportHeadings = {
  user: "User",
  transactionId: "Transaction ID",
  amount: "Amount",
  status: "Status",
  createdAt: "Created At",
};

// TABLE DATA


export const dayOptions = ["Today", "Month", "Year"];


export const createMerchantDefaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  businessName: "",
  businessType: "",
  companyAddress: "",
  state: "",
  country: "",
  companyId: "",
};