import { useState } from "react";
import { ReactComponent as ExportIcon } from "assets/images/icons/export.svg";
import DownloadReportModal from "./DownloadReportModal";

const DownloadReport = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    if (data) setShowModal((prev) => !prev);
  };

  return (
    <div>
      <button
        className="btn btn--sm btn--light-gray--bordered ps-3 pe-3 pe-md-2 pe-lg-4"
        onClick={toggle}
      >
        <ExportIcon className="me-0 me-md-3 me-lg-2" />
        <span className="d-none d-lg-block">Export CSV</span>
      </button>

      {showModal && <DownloadReportModal reportData={data} toggle={toggle} />}
    </div>
  );
};

export default DownloadReport;
