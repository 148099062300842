import { titleCase } from "utils/helpers";
import { GRAY, UNDERLINED } from "utils/helpers/constants";

export default function NavTabs({
	actionList,
	btnList,
	cls,
	rawTitle,
	iconList,
	indexId,
	navId = "myTab",
	navWideMobile,
	tabList = [],
	type,
}) {
	return (
		<span
			className={`nav-tabs-container ${
				navWideMobile ? "nav-wide-mobile" : ""
			}`}
		>
			<ul
				className={`nav nav-tabs overflow-x ${cls}`}
				id={navId}
				role="tablist"
			>
				{tabList?.map((name, i) => {
					const icon = iconList ? iconList[i] : "";
					const action = actionList ? actionList[i] : null;
					const indexedID = indexId ? `${name}${indexId}` : name;
					const tab = btnList
						? btnList[i]
						: `${rawTitle ? name : titleCase(name)}`;
					return (
						<ButtonTab
							active={i === 0 ? "active" : ""}
							id={indexedID}
							tabName={tab}
							Icon={icon}
							key={i}
							handleClick={action}
							type={type}
						/>
					);
				})}
			</ul>
		</span>
	);
}

const ButtonTab = ({ active, id, tabName, Icon, handleClick, type }) => (
	<li className="nav-item" role="presentation">
		<button
			className={`nav-link flex__center ${
				type === UNDERLINED
					? "underlined-nav-link"
					: type === GRAY
					? "gray-nav-link"
					: "bordered-nav-link"
			} ${active}`}
			id={`${id}-tab`}
			data-bs-toggle="tab"
			data-bs-target={`#${id}`}
			role="tab"
			aria-controls={id}
			onClick={handleClick}
		>
			{Icon && <Icon className="me-3" />} {tabName}
		</button>
	</li>
);

export const Tab = ButtonTab;
