import Commissons from "../Commissions/Commissions";
import DashContent from "../DashContent/DashContent";
import MerchantDetails from "../Merchants/MerchantDetails/Profile/MerchantDetails";
import MerchantTransactions from "../Merchants/MerchantDetails/Transactions/Transactions";
import Merchants from "../Merchants/Merchants";
import Prices from "../Prices/Prices";
import Reports from "../Reports/AuditTrail/AuditTrail";
import ServiceReports from "../Reports/ServiceReport/ServiceReport";
import Transactions from "../Reports/Transactions/Transactions";
import Configuration from "../Settings/Configuration/Configuration";
import Notification from "../Settings/Notification/Notifications";
import Payment from "../Settings/Payment/Payments";
import Security from "../Settings/Security/Security";
import Settings from "../Settings/Settings/Settings";
import Team from "../Settings/Team/Team";
import WalletDetails from "../Wallets/WalletDetails/WalletDetails";
import Wallets from "../Wallets/Wallets";

const routes = [
  {
    name: "Home",
    path: "/dashboard",
    component: DashContent,
    exact: true,
  },
  {
    name: "Merchants",
    path: "/merchants",
    dropdown: [
      {
        name: "Merchants",
        path: "/merchants",
        component: Merchants,
        exact: true,
      },
      {
        name: "Profile",
        path: "/merchants/profile/:id",
        component: MerchantDetails,
        exact: true,
      },
      {
        name: "Wallet Transactions",
        path: "/merchants/transactions/:id",
        component: MerchantTransactions,
        exact: true,
      },
      {
        name: "Teams",
        path: "/merchants/teams/:id",
        component: Transactions,
        exact: true,
      },
    ],
  },
  {
    name: "Prices",
    path: "/prices",
    component: Prices,
    exact: true,
  },
  {
    name: "Commissions",
    path: "/commissions",
    component: Commissons,
    exact: true,
  },
  {
    name: "Wallets",
    path: "/wallets",
    dropdown: [
      {
        name: "Wallets",
        path: "/wallets",
        component: Wallets,
        exact: true,
      },
      {
        name: "Wallets",
        path: "/wallets/:id",
        component: WalletDetails,
        exact: true,
      },
    ],
  },
  {
    name: "Reports",
    path: "/reports",
    dropdown: [
      {
        name: "Audit Trail",
        path: "/reports/audit-trail",
        component: Reports,
        exact: true,
      },
      {
        name: "Service Reports",
        path: "/reports/service-reports",
        component: ServiceReports,
        exact: true,
      },
      {
        name: "Transactions",
        path: "/reports/transactions",
        component: Transactions,
        exact: true,
      },
    ],
  },
  {
    name: "Settings",
    path: "/settings",
    dropdown: [
      {
        name: "Payment",
        path: "/settings/partner-profile",
        component: Settings,
        exact: true,
      },
      {
        name: "Payment",
        path: "/settings/payment",
        component: Payment,
        exact: true,
      },
      {
        name: "Payment",
        path: "/settings/security",
        component: Security,
        exact: true,
      },
      {
        name: "Notifications",
        path: "/settings/notification",
        component: Notification,
        exact: true,
      },
      {
        name: "Team",
        path: "/settings/team",
        component: Team,
        exact: true,
      },
      {
        name: "Configuration",
        path: "/settings/customization",
        component: Configuration,
        exact: true,
      },
    ],
  },
  {
    name: "Others Menu",
    path: "/others",
    dropdown: [
      {
        name: "Sub Menu",
        path: "/others/submenu",
        component: () => (
          <h1>Change all this routes to there respective components</h1>
        ),
        exact: true,
      },
    ],
  },
];

export default routes;
