import { useState } from "react";
import { Parser } from "json2csv";
// import { ModalFooter } from "reactstrap";
import { timeDateFormat, titleCase } from "utils/helpers";
import Alert from "utils/Alert/Alert";

const DownloadReportContent = ({ reportData, toggle }) => {
  const [errorMsg, setErrorMsg] = useState();
  const { filters, tableHeadings, data, currentPage, page } = reportData || {};
  const allFilters = Object.entries(filters || {});
  const isEmpty = Object.values(filters).some((val) => val);

  const downloadReport = async () => {
    try {
      setErrorMsg();
      let fields = Object.entries(tableHeadings).map((val) => ({
        label: val[1],
        value: val[0],
      }));
      const json2csvParser = new Parser({ fields });
      const csv = json2csvParser.parse(data);
      let blob = new Blob([csv], {
          type: "text/csv",
        }),
        url = window.URL.createObjectURL(blob);
      window.open(url);
      toggle();
    } catch (e) {
      setErrorMsg("Error Downloading Report!");
    }
  };

  const getData = (filter) => {
    if (filter[1]) {
      if (filter[0] === "from" || filter[0] === "to")
        return (
          <li>
            {titleCase(filter[0])}:{" "}
            <span>{timeDateFormat(filter[1]).date}</span>
          </li>
        );
      else
        return (
          <li>
            {titleCase(filter[0])}: <span>{filter[1]}</span>
          </li>
        );
    } else return "";
  };

  return (
    <>
      <div className="px-5">
        {errorMsg && <Alert message={errorMsg} onClose={() => setErrorMsg()} />}

        {isEmpty && (
          <ul className="report__analysis mb-3">
            <li className="text--md fw-bold border-bottom pb-3">Filters</li>

            {allFilters?.map((filter) => getData(filter))}
          </ul>
        )}

        <ul className="report__analysis mb-5">
          <li className="text--md fw-bold  border-bottom pb-3">
            Page Information
          </li>
          <li>
            Current Page: <span> {currentPage || page}</span>
          </li>
          <li>
            Per Page: <span>{25}</span>
            {/* Per Page: <span>{getRowSize()}</span> */}
          </li>
          <li>
            Total Count: <span>{data?.length || 0}</span>
          </li>
        </ul>
      </div>

        <div className="flex__between w-100 px-5 mb-4">
          <button
            type="button"
            className="btn btn--sm btn--primary--bordered px-5"
            onClick={toggle}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn--sm btn--primary px-5"
            onClick={downloadReport}
          >
            Download
          </button>
        </div>
    </>
  );
};

export default DownloadReportContent;
