import {ReactComponent as InfoCircleError} from "assets/images/icons/info-circle-error.svg";

export default function CustomInputBox({
  type = "text",
  name,
  label,
  labelIcon,
  placeholder,
  value,
  readonly,
  error,
  hide,
  icon,
  iconRight,
  secondary,
  inputCls = "",
  infoText,
  inputLeft,
  onChange,
  onFocus,
  required,
  length,
  question,
  cls = "",
  disabled,
  showError = true,
  iconCls = " p-3",
  ...rest
}) {
  const isError = error ? " error" : "";
  const isRequired = required ? " required" : "";
  const isDisabled = disabled ? " disabled" : "";
  const inputWidth = icon || inputLeft ? "" : " w-100";
  const isSecondary = secondary ? " secondary-input" : "";
  const inputWithIconCls = icon && iconRight ? "rounded-0" : "";

  return (
    <div className={`form-group ${cls}${isSecondary}`}>
      {label && (
        <label
          htmlFor={name}
          className={`form-group-label d-flex${isRequired}`}
        >
          {label}
          {question && <div className="question">?</div>}
          {labelIcon && <div className="ms-2 pointer">{labelIcon}</div>}
        </label>
      )}
      <div
        className={`flex__start input-wrapper${isDisabled}${isError} ${inputCls}`}
      >
        {icon && <div className={`${iconCls} h-100 `}>{icon}</div>}
        {inputLeft}
        {!hide && (
          <input
            className={`w-100 ${inputWithIconCls}${inputWidth}`}
            type={type}
            id={name}
            name={name}
            placeholder={placeholder}
            value={value}
            readOnly={readonly}
            autoComplete="off"
            disabled={disabled}
            onChange={onChange}
            step="any"
            {...rest}
          />
        )}
        {iconRight}
      </div>
      {infoText && <div className="auth-text-gray mt-1">{infoText}</div>}
      {typeof error === "string" && error && showError && (
        <small className="input-text-error">
          <InfoCircleError className="me-2" />
          {error}
        </small>
      )}
    </div>
  );
}
