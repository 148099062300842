import { timeDateFormat } from "utils/helpers";
import SectionWrapper from "utils/section/SectionWrapper/SectionWrapper";
import Table from "utils/Table/Table";
import CommissionAreaChart from "./charts/CommissionAreaChart/CommissionAreaChart";
import InsightChart from "./charts/InsightChart/InsightChart";
import { commissionsTableHeadings } from "./data";
import CommissionsFilter from "./filters/CommissionsFilter";
import { useState } from "react";
import API from "api";
import { getRowSize } from "storage/sessionStorage";
import FailedState from "utils/Modal/Response/FailedState";
import BigSpinner from "utils/Spinner/BigSpinner";
import TableFooter from "utils/Table/TableFooter/TableFooter";
import { useQuery } from "react-query";
import { getCommissions } from "api/commission";

const Commissons = () => {
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(() => getRowSize());

  const handleFilters = (filter = {}) => {
    setFilters((prev) => ({ ...prev, ...filter }));
  };

  const clearFilters = (isDate) => {
    setFilters((prev) => ({}));
    setCurrentPage(0);
  };
  const handlePagination = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleLimitChange = (limit) => {
    setCurrentPage(0);
    setLimit(limit);
  };

  const getCommissionsQuery = useQuery(
    ["totalTransactionHistory", filters, currentPage, limit],
    async () => {
      const response = await API.get(getCommissions, {
        params: { page: currentPage, ...filters, limit },
      });
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const dataParser = (data) => {
    return data?.map((report) => {
      const { date, time } = timeDateFormat(report?.createdAt);
      return {
        service: report?.serviceName,
        price: report?.price,
        commission: `${report?.commission}%`,
        createdAt: `${date} ${time}`,
      };
    });
  };

  const renderByStatus = () => {
    const { isLoading, isError, error, data } = getCommissionsQuery;
    if (isLoading)
      return (
        <div className="w-100 h-500 position-relative flex__column flex__center">
          <BigSpinner />
        </div>
      );
    if (isError)
      return (
        <div className="table__error">
          <FailedState
            onRetry={getCommissionsQuery.refetch}
            title="Error Fetching Merchant Transactions"
            errorMsg={error?.data?.error}
          />
        </div>
      );
    return (
      <>
        <Table
          title=""
          data={dataParser(data?.data)}
          headings={commissionsTableHeadings}
          rowClick={handleRowClick}
        />
        <TableFooter
          currentPage={currentPage || 0}
          totalPages={Math.ceil(data.total / 25)}
          handlePagination={handlePagination}
          handleLimitChange={handleLimitChange}
        />
      </>
    );
  };

  const handleRowClick = (id, rowValues) => {
    // setCurrentRowValues({ ...rowValues });
    // toggleModal();
    console.log("data", rowValues);
  };
  return (
    <>
      <SectionWrapper routeName="Commissions">
        <div className="row mb-4">
          <CommissionAreaChart />
          <InsightChart />
        </div>
        <div className="p-4 bg-white rounded-card">
          <CommissionsFilter
            filters={filters}
            handleFilters={handleFilters}
            clearFilters={clearFilters}
            data={getCommissionsQuery?.data?.data}
          />
          {renderByStatus()}
        </div>
      </SectionWrapper>
    </>
  );
};

export default Commissons;
