import SectionWrapper from "utils/section/SectionWrapper/SectionWrapper";
import { tabs } from "../data";
import CustomInputBox from "utils/inputBox/CustomInputBox/CustomInputBox";
import { useContext, useEffect, useState } from "react";
import CustomSelect from "utils/select/CustomSelect/CustomSelect";
import { useMutation, useQuery } from "react-query";
import API from "api";
import { getBankList, updateBankDetails, verifyBankDetails } from "api/wallet";
import { AuthContext } from "utils/context/AuthContext";
import CustomSpinner from "utils/Spinner/Spinner";
import { ERROR, SUCCESS } from "utils/helpers/constants";
import { toastHandler } from "utils/helpers/toast";

const Payment = () => {
  const userProfile = useContext(AuthContext);
  const { user } = userProfile;
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    accountNumber: user?.bankInformation[0]?.bankAccountNumber,
    bankName: "",
    bankAccountName: "",
  });

  const handleChange = ({ target: { name, value } }) => {
    handleSelect(name, value);
  };
  const handleSelect = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));

    if (name === "bankName") {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
        bankAccountName:'',
      }));
    }
  };

  useEffect(() => {
    if (formValues?.accountNumber?.length === 10) {
      bankDetailsMutation.mutate();
    } else {
      setFormValues((prev) => ({
        ...prev,
        bankAccountName: "",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.accountNumber]);

  const getBankListQuery = useQuery(
    ["bankList"],
    async () => {
      const response = await API.get(getBankList);
      const defaultBank = response?.data?.data?.result?.find(
        (bank) => bank.name === user?.bankInformation[0]?.bankName
      );
      if (defaultBank !== undefined) {
        setFormValues((prev) => ({
          ...prev,
          bankName: { label: defaultBank.name, value: defaultBank.name },
        }));
      }
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isLoading } = useMutation(
    async () => {
      const payload = {
        ...formValues,
        bankAccountNumber: formValues.accountNumber,
        bankName: formValues.bankName.value,
      };
      delete payload.accountNumber;
      const response = await API.put(updateBankDetails, payload);
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 200 && data?.success) {
          userProfile.refetch();
          toastHandler("Bank Details Updated Successfully", SUCCESS);
        }
      },
      onError: (error) => {
        toastHandler("Unable to update bank Infomation", ERROR);
      },
    }
  );
  const bankDetailsMutation = useMutation(
    async () => {
      const payload = {
        accountNumber: formValues.accountNumber,
        bankName: formValues.bankName?.value || user?.bankInformation[0]?.bankName,
        phoneNumber: user.phoneNumber,
      };
      const response = await API.post(verifyBankDetails, payload);
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 200 && data?.data?.success) {
          setFormValues({
            ...formValues,
            bankAccountName: data?.data?.data?.results?.account_name,
            bankName: formValues.bankName || {
              label: user?.bankInformation[0]?.bankName,
              value: user?.bankInformation[0]?.bankName,
            },
          });
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  function handleSubmit(e) {
    e.preventDefault();
    if (formValues.bankAccountName) {
      mutate();
    } else {
      toastHandler("Incorrect Infomation", ERROR);
    }
  }

  return (
    <>
      <SectionWrapper routeName="Payment" tabs={tabs}>
        <div className="p-5 bg-white rounded-card">
          <h4 className="text--grey fw-600">Payment Information</h4>
          <hr className="text--grey mb-5" />

          <form onSubmit={handleSubmit}>
            <div className="row mb-5">
              <div className="col-sm-5">
                <CustomSelect
                  name="bankName"
                  label="Bank Name"
                  onChange={(value) => handleSelect("bankName", value)}
                  options={getBankListQuery?.data?.data?.result.map((bank) => ({
                    label: bank.name,
                    value: bank.name,
                  }))}
                  placeholder="Enter Bank Name"
                  error={formErrors?.bankName}
                  value={formValues?.bankName}
                />
              </div>
              {formValues?.bankName.name}
              <div className="col-sm-5">
                <CustomInputBox
                  label="Account Number"
                  placeholder="Enter Account Number"
                  error={formErrors?.accountNumber}
                  name="accountNumber"
                  onChange={handleChange}
                  value={formValues?.accountNumber}
                />
              </div>
              <div className="col-sm-5">
                <p>{formValues.bankAccountName}</p>
              </div>
            </div>
            <button className="btn btn--primary px-4" onClick={handleSubmit}>
              {isLoading ? <CustomSpinner /> : "Submit"}
            </button>
          </form>
        </div>
      </SectionWrapper>
    </>
  );
};

export default Payment;
