import { useEffect, useState } from "react";

import FilterWrapper from "utils/FilterWrapper/FilterWrapper";
import ExportAndFilter from "components/Reuseables/ExportAndFilter/ExportAndFilter";
import { auditTrailReportHeadings } from "../data";
import CustomSelect from "utils/select/CustomSelect/CustomSelect";
import CustomInputBox from "utils/inputBox/CustomInputBox/CustomInputBox";

const AuditTrailFilters = ({ data, filters, handleFilters, clearFilters }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    activityType: "",
  });
  const [isApplied, setIsApplied] = useState(false);

  useEffect(() => {
    if (filters) {
      setFormValues(filters);
      setIsApplied(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleChange = ({ target: { name, value } }) => {
    handleSelect(name, value);
  };

  const clearFilter = () => {
    setFormValues({ activityType: "" });
    clearFilters();
    setIsApplied(false);
  };

  const handleSubmit = () => {
    handleFilters({
      ...formValues,
    });
    setIsApplied(true);
  };

  return (
    <div className="filters__container">
      <ExportAndFilter
        data={{
          data,
          filters,
          tableHeadings: auditTrailReportHeadings,
          subTitle: "Download Audit Trail  Report",
        }}
        handleFilters={handleFilters}
        clearFilters={clearFilters}
        hideExportBtn={data?.total === 0}
        onClick={() => setIsOpen((prev) => !prev)}
      />
      {isOpen && (
        <FilterWrapper
          handleFilter={handleSubmit}
          clearFilter={clearFilter}
          isApplied={isApplied}
          isFilter
        >
          <div className="form-group">
            {/* <label className="form-group-label mb-2">Activity Type</label> */}
            <div className="col-12">
              <CustomSelect
                name="activityType"
                label="Activity Type"
                onChange={(value) => handleSelect("activityType", value)}
                options={[
                  { label: "Log In", value: "Log In" },
                  {
                    label: "Data Topup Purchase",
                    value: "Data Topup Purchase",
                  },
                  { label: "Airtime Purchase", value: "Airtime Purchase" },
                  { label: "SMS", value: "SMS" },
                  { label: "Wallet Debit", value: "Wallet Debit" },
                  { label: "Wallet Topup", value: "Wallet Topup" },
                ]}
                placeholder="Select Activity Type"
                value={formValues?.activityType}
              />
              <CustomInputBox
                label="Search"
                placeholder="Keyword"
                name="search"
                onChange={handleChange}
                value={formValues?.search}
              />
            </div>
          </div>
        </FilterWrapper>
      )}
    </div>
  );
};

export default AuditTrailFilters;
