import { LAYOUT } from "components/constants";
import styled from "styled-components";

export const SidebarWrapper = styled.div`
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 273px;
  z-index: 3;
  background: var(--primary);
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s linear;
  .top {
    width: -webkit-fill-available;
    .logo {
      padding-top: 40px;
      padding-bottom: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }
  }
  .bottom {
    width: -webkit-fill-available;
    border-radius: 5px;
    padding: 10px;
  }
  @media (max-width: ${LAYOUT.xxLg}) {
    transition: all 0.3s linear;
    position: fixed;
    left: -273px;
    top: 0;
    ${({ open }) => (open ? "left: 0px;" : "left: -273px;")}
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
  }
  @media (max-width: ${LAYOUT.sm}) {
  }
`;
