import axios from "axios";
import Cookies from "js-cookie";
import { TOKEN_KEY } from "utils/helpers/constants";

const baseURLs = [
  process.env.REACT_APP_BASE_URL,
];

const APIs = [];

baseURLs.forEach((url) => {
  let API = axios.create({
    baseURL: url,
    headers: {
      Accept: "application/json",
    },
  });

  API.interceptors.request.use((config) => {
    const token = Cookies.get(TOKEN_KEY);
    // const { configurations } = fetchLocalUserData();
    // const { apiKey, testApiKey } = configurations || {};

    config.headers.Authorization = `Bearer: ${token}`;
    // config.headers["x-api-key"] = testApiKey || apiKey;

    return config;
  });

  API.interceptors.response.use(
    async (response) => {
      return response;
    },
    async (error) => {
      return Promise.reject(error.response);
    }
  );

  APIs.push(API);
});

export default APIs[0];