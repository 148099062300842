import React, { useRef } from "react";

const ScrollToTop = () => {
    const topRef = useRef(null);

    React.useEffect(() => {
        topRef.current.scrollIntoView({ behavior: "smooth" });
    });

    return <div ref={topRef} />;
};

export default ScrollToTop;
