import DashboardLayout from "components/Reuseables/Layout/DashboardLayout/Layout";
import React from "react";
import ScrollToTop from "utils/ScrollToTop/ScrollToTop";
import SubRoutes from "./routes/subRoutes";

const Dashboard = () => {
    return (
        <DashboardLayout>
            <ScrollToTop />
            <SubRoutes />
        </DashboardLayout>
    );
};

export default Dashboard;
