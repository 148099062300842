import { PAGE_INFO } from "utils/helpers/constants";

const CryptoJS = require("crypto-js");

const secretKey = process.env.REACT_APP_ENCRYPY_KEY;

const rowSize = 20;


export const fetchLocalUserData = (storeKey = "localUserData") => {
  try {
    let userData = localStorage.getItem(storeKey);

    if (userData) {
      const bytes = CryptoJS.AES.decrypt(userData, secretKey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    }
    return {};
  } catch (error) {
    throw new Error("Local storage permission is needed");
  }
};

export const storeLocalUserData = (userData, storeKey = "localUserData") => {
  let prevData = fetchLocalUserData(storeKey);
  const data = JSON.stringify({ ...prevData, ...userData });
  var ciphertext = CryptoJS.AES.encrypt(data, secretKey).toString();
  try {
    localStorage.setItem(storeKey, ciphertext);
  } catch (error) {
    throw new Error("Local storage permission is needed");
  }
};

export const deleteLocalUserData = (storeKey = "localUserData") => {
  try {
    localStorage.removeItem(storeKey);
  } catch (error) {
    throw new Error("Local storage permission is needed");
  }
};

export const storeRowSize = (size) => {
  try {
    storeLocalUserData({ size }, PAGE_INFO);
  } catch (error) {
    storeLocalUserData({ size: rowSize }, PAGE_INFO);
  }
};

export const getRowSize = () => {
  try {
    const { size } = fetchLocalUserData(PAGE_INFO);
    if (size === null || size === undefined) return rowSize;
    return size;
  } catch (err) {
    return rowSize;
  }
};