const StepPill = ({ active, index }) => {
  return (
    <div
      // onClick={handleClick}
      className={`step-pill me-4 ${active && "active"}`}
    ></div>
  );
};

export default StepPill;
