import { useEffect, useState } from "react";

import FilterWrapper from "utils/FilterWrapper/FilterWrapper";
import ExportAndFilter from "components/Reuseables/ExportAndFilter/ExportAndFilter";
import { pricesReportHeadings } from "../data";
import CustomSelect from "utils/select/CustomSelect/CustomSelect";
import CustomInputBox from "utils/inputBox/CustomInputBox/CustomInputBox";

const PricesFilters = ({ data, filters, handleFilters, clearFilters }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    service: "",
  });
  const [isApplied, setIsApplied] = useState(false);

  useEffect(() => {
    if (filters) {
      setFormValues(filters);
      setIsApplied(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearFilter = () => {
    setFormValues({});
    clearFilters();
    setIsApplied(false);
  };

  const handleSelect = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChange = ({ target: { name, value } }) => {
    handleSelect(name, value);
  };

  const handleSubmit = () => {
    handleFilters(formValues);
    setIsApplied(true);
  };

  return (
    <div className="filters__container">
      <ExportAndFilter
        data={{
          data,
          filters,
          tableHeadings: pricesReportHeadings,
          subTitle: "Download Prices  Report",
        }}
        handleFilters={handleFilters}
        clearFilters={clearFilters}
        hideExportBtn={data?.total === 0}
        onClick={() => setIsOpen((prev) => !prev)}
      />
      {isOpen && (
        <FilterWrapper
          handleFilter={handleSubmit}
          clearFilter={clearFilter}
          isApplied={isApplied}
          isFilter
        >
          <div className="form-group">
            {/* <label className="form-group-label mb-2">Activity Type</label> */}
            <div className="col-12">
              <CustomSelect
                name="service"
                label="Service"
                onChange={(value) => handleSelect("service", value)}
                options={[
                  {
                    label: "Data",
                    value: "Data",
                  },
                  { label: "Airtime", value: "Airtime" },
                  { label: "SMS", value: "SMS" },
                ]}
                placeholder="Select Service"
                value={formValues?.service}
              />
              <CustomInputBox
                label="Search"
                placeholder="Keyword"
                name="keyword"
                onChange={handleChange}
                value={formValues?.keyword}
              />
            </div>
          </div>
        </FilterWrapper>
      )}
    </div>
  );
};

export default PricesFilters;
