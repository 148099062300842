const route = "/reports";

export const tabs = [
  { label: "Audit Trail", route: `${route}/audit-trail` },
  { label: "Service Reports", route: `${route}/service-reports` },
  { label: "Transactions", route: `${route}/transactions` },
];

// HEADINGS

export const auditTrailTableHeadings = [
  "User",
  "Activity Type",
  "Decsription",
  "Created At",
];
export const auditTrailReportHeadings = {
  firstName: "firstName",
  lastName: "lastName",
  description: "description",
  activityType: "activityType",
  createdAt: "Created At",
};

export const serviceReportsTableHeadings = [
  "Name",
  "Group Name",
  "Service Type",
  "Service",
  "Total Recipients",
  "Total Delivered",
  "Created At",
];

export const serviceReportsDownloadHeadings = {
  firstName: "First Name",
  lastName: "Last Name",
  groupName: "Group Name",
  serviceType: "Service Type",
  service: "Service",
  totalReceipients: "Total Recipients",
  totalDelivered: "Total Delivered",
  createdAt: "Created At",
};

export const transactionsTableHeadings = [
  "User",
  "Transaction ID",
  "Amount",
  "Type",
  "Balance",
  "Category",
  "Status",
  "Created At",
];

export const auditTrailFiltersDefaultValues = {
  "status[0]": null,
  "status[1]": null,
  "status[2]": null,
  "action[0]": null,
  "action[1]": null,
};
export const serviceReportsFiltersDefaultValues = {
  "status[0]": null,
  "status[1]": null,
  "status[2]": null,
  "action[0]": null,
  "action[1]": null,
};
export const transactionsFiltersDefaultValues = {
  "status[0]": null,
  "status[1]": null,
  "status[2]": null,
  "action[0]": null,
  "action[1]": null,
};

// TABLE DATA

export const transactionsTableData = [
  {
    name: "Yemi Williams",
    company: "Anderson Poak",
    transId: "TRNX4345746",
    amount: 15,
    currency: "NGN",
    category: "SMS Category",
    status: "Success",
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    name: "Yemi Williams",
    company: "Anderson Poak",
    transId: "TRNX4345746",
    amount: 15,
    currency: "NGN",
    category: "SMS Category",
    status: "Success",
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    name: "Yemi Williams",
    company: "Anderson Poak",
    transId: "TRNX4345746",
    amount: 15,
    currency: "NGN",
    category: "SMS Category",
    status: "Success",
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    name: "Yemi Williams",
    company: "Anderson Poak",
    transId: "TRNX4345746",
    amount: 15,
    currency: "NGN",
    category: "SMS Category",
    status: "Success",
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    name: "Yemi Williams",
    company: "Anderson Poak",
    transId: "TRNX4345746",
    amount: 15,
    currency: "NGN",
    category: "SMS Category",
    status: "Success",
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    name: "Yemi Williams",
    company: "Anderson Poak",
    transId: "TRNX4345746",
    amount: 15,
    currency: "NGN",
    category: "SMS Category",
    status: "Success",
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    name: "Yemi Williams",
    company: "Anderson Poak",
    transId: "TRNX4345746",
    amount: 15,
    currency: "NGN",
    category: "SMS Category",
    status: "Success",
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    name: "Yemi Williams",
    company: "Anderson Poak",
    transId: "TRNX4345746",
    amount: 15,
    currency: "NGN",
    category: "SMS Category",
    status: "Success",
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    name: "Yemi Williams",
    company: "Anderson Poak",
    transId: "TRNX4345746",
    amount: 15,
    currency: "NGN",
    category: "SMS Category",
    status: "Success",
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    name: "Yemi Williams",
    company: "Anderson Poak",
    transId: "TRNX4345746",
    amount: 15,
    currency: "NGN",
    category: "SMS Category",
    status: "Success",
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    name: "Yemi Williams",
    company: "Anderson Poak",
    transId: "TRNX4345746",
    amount: 15,
    currency: "NGN",
    category: "SMS Category",
    status: "Success",
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
];


// rebuild