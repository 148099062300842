export const loadingOption = {
  text: "Updating Chart...",
  color: "#17568BAA",
  textColor: "#17568BAA",
  maskColor: "#FFFFFFCC",
};

export const chartDefaults = {
  grid: {
    bottom: 0,
    containLabel: true,
    left: 0,
    right: 0,
    top: 0,
  },
  textStyle: {
    color: "#000000",
    fontFamily: "gilroy-regular",
    fontSize: 11,
    fontWeight: 500,
    overflow: "truncate",
    lineOverflow: "truncate",
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      z: 10,
    },
  },
};

export const totalCommissionChartOptions = (data) => {
  const chartData = data || [];
  return {
    ...chartDefaults,
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: ["", ...chartData.map((dt) => dt.month || dt.day), ""],
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
    },
    yAxis: {
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      type: "value",
    },
    series: [
      {
        data: [0, ...chartData.map((dt) => dt.amount), 0],
        type: "line",
        areaStyle: {},
        showSymbol: false,
        lineStyle: {
          color: "rgba(91, 152, 210, 1)",
          width: 5,
        },
        smooth: true,
      },
    ],
    color: "rgba(91, 152, 210, 0.4)",
  };
};
