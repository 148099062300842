import { UNDERLINED } from "utils/helpers/constants";
import SectionContainer from "../SectionContainer/SectionContainer";
import SectionNav from "../SectionNav/SectionNav";

const SectionWrapper = ({
  cls,
  containerCls,
  children,
  routeName,
  headerChildren,
  navTabChildren,
  tabs,
  goBackRoute,
}) => {
  return (
    <SectionContainer cls={containerCls} tabType={UNDERLINED}>
      {(routeName || tabs) && (
        <SectionNav
          cls={cls}
          routeName={routeName}
          navTabChildren={navTabChildren}
          tabs={tabs}
          goBackRoute={goBackRoute}
        >
          {headerChildren}
        </SectionNav>
      )}
      {children}
    </SectionContainer>
  );
};

export default SectionWrapper;
