export const loadingOption = {
  text: "Updating Chart...",
  color: "#17568BAA",
  textColor: "#17568BAA",
  maskColor: "#FFFFFFCC",
};

export const chartDefaults = {
  grid: {
    bottom: 0,
    containLabel: true,
    left: 0,
    right: 0,
    top: 0,
  },
  textStyle: {
    color: "#000000",
    fontFamily: "gilroy-regular",
    fontSize: 11,
    fontWeight: 500,
    overflow: "truncate",
    lineOverflow: "truncate",
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      z: 10,
    },
  },
};

export const totalComChartOptions = (chartData) => {
  return {
    ...chartDefaults,
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: ['', "Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun", ''],
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
    },
    yAxis: {
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      type: "value",
    },
    series: [
      {
        data: [0, 420, 232, 701, 534, 1290, 630, 1320,0],
        type: "bar",
        showSymbol: false,
        barWidth: 14,
        itemStyle: {
          borderRadius: 4,
          color: "rgba(91, 152, 210, 0.4)",
        },
      },
      {
        data: [0, 820, 932, 901, 934, 290, 1330, 1320, 0],
        type: "bar",
        showSymbol: false,
        barWidth: 14,
        itemStyle: {
          borderRadius: 4,
          color: "rgba(91, 152, 210, 1)",
        },
      },
    ],
    color: "rgba(91, 152, 210, 0.4)",
  };
};
