import { useContext, useEffect, useState } from "react";
import { Progress } from "reactstrap";
import ReactECharts from "echarts-for-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { dayOptions } from "../../data";
import { DashbaordAreaChartWrapper } from "./DashbaordAreaChartWrapper";
import { loadingOption, totalSalesChartOptions } from "../../helpers";
import { ReactComponent as InfoCircleGray } from "assets/images/icons/info-circle-gray.svg";
import { ReactComponent as CaretSuccess } from "assets/images/icons/caret-success.svg";
import StyledDateSelect from "utils/select/DateSelect/StyledDateSelect";
import { useQuery } from "react-query";
import API from "api";
import { getMerchantSales } from "api/dashboard";
import { toCurrency } from "utils/helpers";
import { AuthContext } from "utils/context/AuthContext";

function DashbaordAreaChart() {
  const [dateRange, setDateRange] = useState("Week");
  let timer;

  const { id } = useContext(AuthContext);
  useEffect(() => {
    return () => clearTimeout(timer);
  });

  const getSalesQuery = useQuery(
    ["totalSales", dateRange, id],
    async () => {
      const response = await API.get(getMerchantSales, {
        params: {
          companyId: id,
          period: dateRange.toLowerCase(),
        },
      });
      return response.data.data;
    },
    {
      enabled: !!id,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  let sum = 0;
  if (getSalesQuery?.isSuccess) {
    sum = getSalesQuery?.data?.walletActivities.reduce(
      (accumulator, object) => {
        return accumulator + object.amount;
      },
      0
    );
  }

  return (
    <DashbaordAreaChartWrapper className="col-md-6 col-lg-4 p-4 pl-0">
      <div className="bg-white px-0 rounded-card">
        <div className="flex__between p-5 flex-wrap">
          <div>
            <p className="text--grey heading-4">
              Total Sale this {dateRange}{" "}
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-right">
                    Total Amount of money that was paid into your wallet. This
                    is the sum of all payments plus charges
                  </Tooltip>
                }
              >
                <span className="d-inline-block">
                  <InfoCircleGray className="ms-2 pointer" />
                </span>
              </OverlayTrigger>
            </p>
            <p className="black text--xl text-break">N {toCurrency(sum)}</p>
          </div>
          <StyledDateSelect
            wrapperCls="col-2 col-sm-2"
            cls="col-9 col-sm-10"
            name="date-select"
            formLabel="date-select"
            onChange={(e) => setDateRange(e)}
            options={dayOptions}
            value={dateRange}
          />
        </div>
        <ReactECharts
          loadingOption={loadingOption}
          option={totalSalesChartOptions(getSalesQuery?.data)}
          opts={{ renderer: "svg" }}
          showLoading={getSalesQuery.isLoading}
          style={{
            height: "180px",
            minWidth: "100%",
            margin: "auto",
            paddingBottom: "20px",
          }}
        />

        <div className="row px-2">
          <div className="col-6 p-4 pe-2">
            <div className="bg-secondary-accent-theta p-4 rounded">
              <div className="flex__between">
                <div>
                  <p className="text--grey heading-4 mt-0 text--sm m-0">
                    Total Sale this week
                  </p>
                  <p className="black text--xslg text-break m-0 mb-4">
                    N
                    {toCurrency(
                      getSalesQuery?.data?.total?.weekUsagae?.amount || 0
                    )}
                  </p>
                </div>
                <div>
                  <CaretSuccess />
                </div>
              </div>
              <Progress
                className="col-12 gray mt-3"
                striped
                animated
                color="gray"
                value={((new Date().getDay() + 1) / 7) * 100}
              />
            </div>
          </div>
          <div className="col-6 p-4 ps-2">
            <div className="bg-secondary-accent-theta p-4 rounded">
              <div className="flex__between">
                <div>
                  <p className="text--grey heading-4 mt-0 text--sm m-0">
                    Total Sale this month
                  </p>
                  <p className="black text--xslg text-break m-0 mb-4">
                    N
                    {toCurrency(
                      getSalesQuery?.data?.total?.monthUsage?.amount || 0
                    )}
                  </p>
                </div>
                <div>
                  <CaretSuccess />
                </div>
              </div>
              <Progress
                className="col-12 gray mt-3"
                striped
                animated
                color="gray"
                value={
                  (new Date().getDate() /
                    parseInt(
                      new Date(
                        new Date().getFullYear(),
                        new Date().getMonth() + 1,
                        0
                      )
                        .toString()
                        .split(" ")[2]
                    )) *
                  100
                }
              />
            </div>
          </div>
          <div className="col-sm-12 px-4 pb-4">
            <div className="bg-secondary-accent-theta p-4 rounded">
              <div className="flex__between">
                <div>
                  <p className="text--grey heading-4 mt-0 text--sm m-0">
                    Total Sale this year
                  </p>
                  <p className="black text--xslg text-break m-0">
                    N
                    {toCurrency(
                      getSalesQuery?.data?.total?.yearUsage?.amount || 0
                    )}
                  </p>
                </div>
                <div>
                  <CaretSuccess />
                  {/* <p className="mt-3 mb-0 fw-600 text--md">20%</p> */}
                </div>
              </div>
              <Progress
                className="col-12 gray mt-3"
                striped
                animated
                color="gray"
                value={((new Date().getMonth() + 1) / 12) * 100}
              />
            </div>
          </div>
        </div>
      </div>
    </DashbaordAreaChartWrapper>
  );
}

export default DashbaordAreaChart;
