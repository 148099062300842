import Select from "react-select";
import { ReactComponent as InfoCircleError } from "assets/images/icons/info-circle-error.svg";

export default function CustomSelect({
  label,
  LabelIcon,
  name,
  value,
  error,
  isMulti,
  isClearable,
  icon,
  inputCls,
  inputLeft,
  labelChildren,
  options,
  placeholder = "",
  secondary,
  required,
  disabled,
  isLoading,
  getOptionLabel,
  getOptionValue,
  formGroupCls,
  onChange,
  onInputChange,
  showError = true,
  infoText,
  ...rest
}) {
  const cls = `form-group-select${isMulti ? "--is-multi" : ""}`;
  const isRequired = required ? " required" : "";
  const isError = error ? "_error" : "";
  const wrapperError = error ? " error" : "";
  const isSecondary = secondary ? " secondary-select" : "";
  const isLabelChildren = labelChildren ? " col-12 flex__between" : "";

  return (
    <div style={{ zIndex: 1000 }}>
      <div className={`form-group${isSecondary} ${formGroupCls}`}>
        {label && (
          <label
            htmlFor={name}
            className={`form-group-label${isRequired}${isLabelChildren}`}
          >
            {label}
            {LabelIcon && <LabelIcon className="ms-2 pointer my-auto" />}
            {labelChildren}
          </label>
        )}

        <div
          className={`d-flex align-items-center input-wrapper${wrapperError}`}
        >
          {icon && <div className="px-3">{icon}</div>}
          {inputLeft}
          <div className={`${inputCls} ${cls}${isError}`}>
            <Select
              onInputChange={onInputChange}
              isClearable={isClearable}
              inputId={name}
              name={name}
              placeholder={placeholder}
              isMulti={isMulti}
              options={options}
              value={value}
              isLoading={isLoading || !options}
              isDisabled={disabled}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              classNamePrefix={cls}
              onChange={onChange}
              // menuPortalTarget={document.body}
              // styles={{
              // 	menuPortal: (base) => ({
              // 		...base,
              // 		zIndex: 9999,
              // 	}),
              // }}
              {...rest}
            />
          </div>
        </div>
        {infoText && <span className="auth-text-gray mt-1">{infoText}</span>}
        {typeof error === "string" && error && showError && (
          <small className="input-text-error">
            <InfoCircleError className="me-2" />
            {error}
          </small>
        )}
      </div>
    </div>
  );
}
