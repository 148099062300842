import { State } from "country-state-city";
import CustomSelect from "../CustomSelect/CustomSelect";

const StateSelect = ({
    label,
	LabelIcon,
	name,
	value,
	error,
	isMulti,
	icon,
	inputCls,
	inputLeft,
	labelChildren,
	placeholder = "",
    countryCode,
	secondary,
	required,
	disabled,
	getOptionLabel,
	getOptionValue,
	formGroupCls,
	onChange,
  showError,
    ...rest
}) => {

    const parsedStates = (countryCode) =>
        State.getStatesOfCountry(countryCode)
            .map(state => ({ label: state?.name, value: state?.isoCode, ...state }));
    return(
        <CustomSelect 
            label={label}
	        LabelIcon={LabelIcon}
	        name={name}
	        value={value}
	        error={error}
	        isMulti={isMulti}
	        icon={icon}
	        inputCls={inputCls}
	        inputLeft={inputLeft}
	        labelChildren={labelChildren}
	        options={parsedStates(countryCode)}
	        placeholder={placeholder}
	        secondary={secondary}
	        required={required}
	        disabled={disabled}
	        getOptionLabel={getOptionLabel}
	        getOptionValue={getOptionValue}
	        formGroupCls={formGroupCls}
	        onChange={onChange}
          showError={showError}
            {...rest}
        />
    )
}

export default StateSelect;