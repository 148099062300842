import styled from "styled-components";

export const InsightChartWrapper = styled.div`
  .bg--primary {
    min-height: 140px;
  }
  .light-select {
    .MuiInputBase-input {
      color: #ffffff !important;
    }
  }
`;
