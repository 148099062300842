import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import StyledDateSelect from "utils/select/DateSelect/StyledDateSelect";
import { dayOptions } from "../../data";
import API from "api";
import { CommissionAreaChartWrapper } from "./CommissionAreaChartWrapper";
import { loadingOption, totalCommissionChartOptions } from "../../helpers";
import { ReactComponent as InfoCircleGray } from "assets/images/icons/info-circle-gray.svg";
import { useQuery } from "react-query";
import { getCommissionsChart } from "api/commission";
import { toCurrency } from "utils/helpers";

function CommissionAreaChart() {
  const [dateRange, setDateRange] = useState("daily");
  let timer;

  useEffect(() => {
    return () => clearTimeout(timer);
  });

  function onChartReady(echarts) {
    timer = setTimeout(function () {
      echarts.hideLoading();
    }, 3000);
  }

  const getCommissionsChartQuery = useQuery(
    ["commissionsChart", dateRange],
    async () => {
      const response = await API.get(getCommissionsChart, {
        params: {
          period: dateRange.toLowerCase(),
        },
      });
      return response.data.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  let sum = 0;
  if (getCommissionsChartQuery?.isSuccess) {
    sum = getCommissionsChartQuery?.data?.reduce((accumulator, object) => {
      return accumulator + object.amount;
    }, 0);
  }

  return (
    <CommissionAreaChartWrapper className="col-md-8 p-4 pl-0">
      <div className="bg-white px-0 rounded-card">
        <div className="flex__between p-5 ">
          <div>
            <p className="text--grey heading-4">
              Total Commissions{" "}
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-right">
                    Total Amount of money that was paid into your wallet. This
                    is the sum of all payments plus charges
                  </Tooltip>
                }
              >
                <span className="d-inline-block">
                  <InfoCircleGray className="ms-2 pointer" />
                </span>
              </OverlayTrigger>
            </p>
            <p className="black text--xl text-break">N {toCurrency(sum)}</p>
          </div>
          <StyledDateSelect
            wrapperCls="col-5 col-sm-2"
            cls="col-9 col-sm-10"
            name="date-select"
            formLabel="date-select"
            onChange={(e) => setDateRange(e)}
            options={dayOptions}
            value={dateRange}
          />
        </div>
        <ReactECharts
          loadingOption={loadingOption}
          onChartReady={onChartReady}
          option={totalCommissionChartOptions(getCommissionsChartQuery?.data)}
          opts={{ renderer: "svg" }}
          showLoading={getCommissionsChartQuery.isLoading}
          style={{
            height: "180px",
            minWidth: "100%",
            margin: "auto",
            paddingBottom: "20px",
          }}
        />
      </div>
    </CommissionAreaChartWrapper>
  );
}

export default CommissionAreaChart;
