import { getError, timeDateFormat, toCurrency } from "utils/helpers";
import SectionWrapper from "utils/section/SectionWrapper/SectionWrapper";
import Table from "utils/Table/Table";
import { pricesTableHeadings } from "./data";
import PricesFilters from "./filters/PricesFilters";
import { useState } from "react";
import { getRowSize } from "storage/sessionStorage";
import { useQuery } from "react-query";
import API from "api";
import { getPrices } from "api/prices";
import FailedState from "utils/Modal/Response/FailedState";
import BigSpinner from "utils/Spinner/BigSpinner";
import TableFooter from "utils/Table/TableFooter/TableFooter";

const Prices = () => {
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(() => getRowSize());

  const handleRowClick = (id, rowValues) => {
    // setCurrentRowValues({ ...rowValues });
    // toggleModal();
    console.log("data", rowValues);
  };

  const handleFilters = (filter = {}) => {
    setFilters((prev) => ({ ...prev, ...filter }));
  };

  const clearFilters = (isDate) => {
    setFilters((prev) => ({}));
    setCurrentPage(0);
  };
  const handlePagination = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleLimitChange = (limit) => {
    setCurrentPage(0);
    setLimit(limit);
  };

  const getPricesQuery = useQuery(
    ["totalPrices", filters, currentPage, limit],
    async () => {
      const response = await API.get(getPrices, {
        params: {
          page: currentPage,
          ...filters,
          service: filters?.service?.value,
          limit,
        },
      });
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const dataParser = (data) => {
    return data?.map((report) => {
      const { date, time } = timeDateFormat(report?.createdAt);
      return {
        services: report?.priceModelName,
        country: report?.country,
        countryCode: report?.countryCode,
        type: report?.type,
        val: report?.value
          ? `${report?.value}%`
          : `${report?.currency}${toCurrency(report?.price)}`,
        createdAt: `${date} ${time}`,
      };
    });
  };

  const renderByStatus = () => {
    const { isLoading, isError, error, data } = getPricesQuery;
    if (isLoading)
      return (
        <div className="w-100 h-500 position-relative flex__column flex__center">
          <BigSpinner />
        </div>
      );
    if (isError)
      return (
        <div className="table__error">
          <FailedState
            onRetry={getPricesQuery.refetch}
            title="Error Fetching Merchant Transactions"
            errorMsg={getError(error)}
          />
        </div>
      );
    return (
      <>
        <Table
          title=""
          data={dataParser(data?.data)}
          headings={pricesTableHeadings}
          rowClick={handleRowClick}
        />
        <TableFooter
          currentPage={currentPage || 0}
          totalPages={Math.ceil(data.total / 25)}
          handlePagination={handlePagination}
          handleLimitChange={handleLimitChange}
        />
      </>
    );
  };
  return (
    <>
      <SectionWrapper routeName="Prices">
        <div className="p-4 bg-white rounded-card">
          <PricesFilters
            filters={filters}
            handleFilters={handleFilters}
            clearFilters={clearFilters}
            data={getPricesQuery?.data?.data}
          />
          {renderByStatus()}
        </div>
      </SectionWrapper>
    </>
  );
};

export default Prices;
