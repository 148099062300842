export const loginUser = "/auth/login-partner";
export const getUserProfile = "/auth/users/profile/partner";
export const resetPassword = "/auth/change/password/reset";
export const getBusinessType = "/auth/list/business/type";
export const toggle2fa = "/auth/users/set2fa";
export const verifyDisable2fa = "/auth/account/verification/disable-two-fa";
export const verifyLogin2fa = "/auth/account/verification/two-fa/partner";
export const enableAppAuth = "/auth/users/set-app2fa";
export const verifyAppAuth = "/auth/account/authenticator/verify/two-fa";
export const disableAppAuth = "/auth/account/authenticator/disable/two-fa";
export const verifyLoginAppAuth = "/auth/account/authenticator/login/partner";
export const forgotPassword = "/auth/partner/reset/password";
export const verifyResetPassword = "/auth/partner/verify/password-reset";
