import { useEffect, useState } from "react";

import { ReactComponent as EllipseIcon } from "assets/images/icons/ellipse.svg";

import FilterWrapper from "utils/FilterWrapper/FilterWrapper";
import CustomCheckBox from "utils/CustomCheckBox/CustomCheckBox";
import ExportAndFilter from "components/Reuseables/ExportAndFilter/ExportAndFilter";
import { transactionsReportHeadings } from "../data";

const TransactionHistoryFilters = ({
  data,
  filters,
  handleFilters,
  clearFilters,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState({ status: "", actionType : ''});
  const [isApplied, setIsApplied] = useState(false);

  useEffect(() => {
    if (filters) {
      setFormValues(filters);
      setIsApplied(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (name, value) => {

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

  };

  const clearFilter = () => {
    setFormValues({});
    clearFilters();
    setIsApplied(false);
  };

  const handleSubmit = () => {
    handleFilters({ ...formValues, interval: formValues?.interval?.value });
    setIsApplied(true);
  };

 
  return (
    <div className="filters__container">
      <ExportAndFilter
        data={{
          data,
          filters,
          tableHeadings: transactionsReportHeadings,
          subTitle: "Download Transaction History Report",
        }}
        handleFilters={handleFilters}
        clearFilters={clearFilters}
        hideExportBtn={data?.total === 0}
        onClick={() => setIsOpen((prev) => !prev)}
      />
      {isOpen && (
        <FilterWrapper
          handleFilter={handleSubmit}
          clearFilter={clearFilter}
          isApplied={isApplied}
          isFilter
        >
          <div className="form-group">
            <label className="form-group-label mb-2">Filter Status</label>
            <div className="col-12">
              <CustomCheckBox
                checked={formValues["status"] === "success"}
                label={
                  <>
                    <EllipseIcon className="status--success" />
                    <span>Success</span>
                  </>
                }
                name="success"
                onClick={() => handleSelect("status", "success")}
              />

              <CustomCheckBox
                checked={formValues["status"] === "pending"}
                label={
                  <>
                    <EllipseIcon className="status--pending" />
                    <span>Pending</span>
                  </>
                }
                name="pending"
                onClick={() => handleSelect("status", "pending")}
              />

              <CustomCheckBox
                checked={formValues["status"] === "failed"}
                label={
                  <>
                    <EllipseIcon className="status--failed" />
                    <span>Failed</span>
                  </>
                }
                name="failed"
                onClick={() => handleSelect("status", "failed")}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-group-label mb-2">Filter Type</label>
            <div className="col-12">
              <CustomCheckBox
                checked={formValues["actionType"] === "credit"}
                label={
                  <>
                    <EllipseIcon className="status--success" />
                    <span>Credit</span>
                  </>
                }
                name="credit"
                onClick={() => handleSelect("actionType", "credit")}
              />

              <CustomCheckBox
                checked={formValues["actionType"] === "debit"}
                label={
                  <>
                    <EllipseIcon className="status--failed" />
                    <span>Debit</span>
                  </>
                }
                name="debit"
                onClick={() => handleSelect("actionType", "debit")}
              />
            </div>
          </div>
        </FilterWrapper>
      )}
    </div>
  );
};

export default TransactionHistoryFilters;
