import { ModalFooter } from "reactstrap";
import {ReactComponent as GreenTickCircleIcon} from "assets/images/icons/green-tick-circle.svg";

const TaskCompletionStep = ({ cls, subText, title, children, footerChildren, id }) => (
    <div className='text-center'>
        <div className={cls} id={id}>
            <GreenTickCircleIcon className='mb-5' />
            <div className='col-12 col-sm-11 m-auto'>
                <p className='heading-1'>{title}</p>
                <p className='auth-text-gray heading-3'>{subText}</p>
            </div>
            {children}
        </div>

        {footerChildren && <ModalFooter>{footerChildren}</ModalFooter>}
    </div>
);

export default TaskCompletionStep;
