import { useState } from "react";
import { Menu } from "@mui/material";
import {ReactComponent as MoreVertIcon} from "assets/images/icons/more-v.svg";

const BrailleDropDown = ({ data, setSelected, component: Component }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl);

	const handleClick = ({ target }) => setAnchorEl(target);

	const handleClose = () => setAnchorEl(null);

	return (
		<>
			<MoreVertIcon onClick={handleClick} />

			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				transformOrigin={{ horizontal: "center" }}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				<Component
					data={data}
					setSelected={setSelected}
					handleClose={handleClose}
				/>
			</Menu>
		</>
	);
};

export default BrailleDropDown;
