import { useEffect, useRef, useState } from "react";
import Sidebar from "../Sidebar/Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import { DashboardWrapper } from "./DashboardWrapper";

const DashboardLayout = ({children}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);
  return (
    <DashboardWrapper>
      <Sidebar open={open} />
      <div className="main p-4">
        <Navbar open={open} setOpen={setOpen} navRef={ref} />
        {children}
      </div>
    </DashboardWrapper>
  );
};

export default DashboardLayout;

