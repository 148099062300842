import Cookies from "js-cookie";
import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { TOKEN_KEY } from "utils/helpers/constants";

const PrivateRoutes = ({ component: Component, ...rest }) => {
  const token = Cookies.get(TOKEN_KEY);

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default withRouter(PrivateRoutes);
