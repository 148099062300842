import StyledDateSelect from "utils/select/DateSelect/StyledDateSelect";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { dayOptions } from "../../data";
import API from "api";
import { ReactComponent as InfoCircleGray } from "assets/images/icons/info-circle-gray.svg";
import { useState } from "react";
import { useQuery } from "react-query";
import { toCurrency } from "utils/helpers";
import { getSingleMerchantWalletInflow } from "api/merchant";

function MerchantWalletChart({ id, getWalletBalanceQuery }) {
  const [dateRange1, setDateRange1] = useState("Today");
  const [dateRange2, setDateRange2] = useState("Today");

  const getWalletInflowQuery = useQuery(
    ["WalletInflow", dateRange1],
    async () => {
      const response = await API.get(getSingleMerchantWalletInflow(id), {
        params: { action: "inflow", period: dateRange1.toLowerCase() },
      });
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const getWalletOutflowQuery = useQuery(
    ["WalletOutflow", dateRange2],
    async () => {
      const response = await API.get(getSingleMerchantWalletInflow(id), {
        params: { action: "outflow", period: dateRange2.toLowerCase() },
      });
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className="row">
      <div className="col-md-6 col-lg-4 p-4 pl-0">
        <div className="bg-white px-0 rounded-card">
          <div className="flex__between p-5 flex-wrap">
            <div>
              <p className="text--grey heading-4">
                Your Wallet Balance{" "}
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-right">
                      Total Amount of money that was paid into your wallet. This
                      is the sum of all payments plus charges
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <InfoCircleGray className="ms-2 pointer" />
                  </span>
                </OverlayTrigger>
              </p>
              <div className="d-flex">
                <p className="black text--xl text-break">
                  N{toCurrency(getWalletBalanceQuery?.data?.data?.balance)}
                </p>
                {/* <span className="text-success mt-3 fw-600 ms-3">0.38%</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 p-4 pl-0">
        <div className="bg--success-transparent px-0 rounded-card">
          <div className="flex__between p-5 flex-wrap">
            <div>
              <p className="text--grey heading-4">
                Your Wallet Info
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-right">
                      Total Amount of money that was paid into your wallet. This
                      is the sum of all payments plus charges
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <InfoCircleGray className="ms-2 pointer" />
                  </span>
                </OverlayTrigger>
              </p>
              <div className="d-flex">
                <p className="black text--xl text-break">
                  N
                  {toCurrency(
                    getWalletInflowQuery?.data?.data?.totalAmountCurrentPeriod
                  )}
                </p>
                <span className="text-success mt-3 fw-600 ms-3">
                  {" "}
                  {getWalletInflowQuery?.data?.data?.percentageIncrease}%
                </span>
              </div>
            </div>
            <StyledDateSelect
              cls="col-9 col-sm-10 bg-white"
              name="date-select"
              formLabel="date-select"
              onChange={(e) => setDateRange1(e)}
              value={dateRange1}
              options={dayOptions}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 p-4 pl-0">
        <div className="bg--danger-transparent px-0 rounded-card">
          <div className="flex__between p-5 flex-wrap">
            <div>
              <p className="text--grey heading-4">
                Your Wallet Outflow
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-right">
                      Total Amount of money that was paid into your wallet. This
                      is the sum of all payments plus charges
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <InfoCircleGray className="ms-2 pointer" />
                  </span>
                </OverlayTrigger>
              </p>
              <div className="d-flex">
                <p className="black text--xl text-break">
                  N
                  {toCurrency(
                    getWalletOutflowQuery?.data?.data?.totalAmountCurrentPeriod
                  )}
                </p>
                <span className="text-success mt-3 fw-600 ms-3">
                  {getWalletOutflowQuery?.data?.data?.percentageIncrease}%
                </span>
              </div>
            </div>
            <StyledDateSelect
              wrapperCls="col-2 col-sm-2"
              cls="col-9 col-sm-10"
              name="date-select"
              formLabel="date-select"
              onChange={(e) => setDateRange2(e)}
              value={dateRange2}
              options={dayOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MerchantWalletChart;
