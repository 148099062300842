import { useContext, useState } from "react";
import ReactECharts from "echarts-for-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { dayOptions } from "../../data";
import { loadingOption, totalRevChartOptions } from "../../helpers";
import { ReactComponent as InfoCircleGray } from "assets/images/icons/info-circle-gray.svg";
import { InsightChartWrapper } from "./InsightChartWrapper";
import { ReactComponent as InfoSecondary } from "assets/images/icons/info-secondary.svg";
import StyledDateSelect from "utils/select/DateSelect/StyledDateSelect";
import { useQuery } from "react-query";
import API from "api";
import { getInsight, getMerchantRevenue } from "api/dashboard";
import { toCurrency } from "utils/helpers";
import { AuthContext } from "utils/context/AuthContext";

function InsightChart() {
  const [dateRange, setDateRange] = useState("Week");
  const [dateRange2, setDateRange2] = useState("Week");

  const { id } = useContext(AuthContext);
  const getInsightQuery = useQuery(
    ["totalInsight", dateRange2],
    async () => {
      const response = await API.get(getInsight, {
        params: {
          period: dateRange2.toLowerCase(),
        },
      });
      return response.data.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const getRevenueQuery = useQuery(
    ["totalRevenue", dateRange],
    async () => {
      const response = await API.get(getMerchantRevenue, {
        params: {
          companyId: id,
          period: dateRange.toLowerCase(),
        },
      });
      return response.data.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  let sum = 0;
  if (getRevenueQuery?.isSuccess) {
    sum = getRevenueQuery?.data?.reduce((accumulator, object) => {
      return accumulator + object.amount;
    }, 0);
  }
  return (
    <InsightChartWrapper className="col-md-12 col-lg-4 p-4 pl-0">
      <div className="bg-white px-0 rounded-card mb-4">
        <div className="flex__between p-5 flex-wrap">
          <div>
            <p className="text--grey heading-4">
              Total Revenue{" "}
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-right">
                    Total Amount of money that was paid into your wallet. This
                    is the sum of all payments plus charges
                  </Tooltip>
                }
              >
                <span className="d-inline-block">
                  <InfoCircleGray className="ms-2 pointer" />
                </span>
              </OverlayTrigger>
            </p>
            <p className="black text--xl text-break">N{toCurrency(sum)}</p>
          </div>
          <StyledDateSelect
            wrapperCls="col-5 col-sm-5"
            cls="col-9 col-sm-10"
            name="date-select"
            formLabel="date-select"
            onChange={(e) => setDateRange(e)}
            options={dayOptions}
            value={dateRange}
          />
        </div>
        <ReactECharts
          loadingOption={loadingOption}
          option={totalRevChartOptions(getRevenueQuery)}
          opts={{ renderer: "svg" }}
          showLoading={getRevenueQuery.isLoading}
          style={{
            height: "180px",
            minWidth: "100%",
            margin: "auto",
            paddingBottom: "20px",
          }}
        />
      </div>
      <div className="bg--primary p-5 rounded-card text-light">
        <div className="flex__between light-select">
          <div>
            <p className="heading-4">
              <InfoSecondary /> <span className="mx-4">Insight</span>
            </p>
          </div>
          <StyledDateSelect
            wrapperCls="col-9 col-sm-3 "
            cls="col-9 col-sm-10 "
            name="date-select"
            formLabel="date-select"
            onChange={(e) => setDateRange2(e)}
            options={["Week", "Month"]}
            value={dateRange2}
          />
        </div>
        <div className="d-flex py-2">
          <div>
            <p className="black text--xl text-break">
              {getInsightQuery?.data?.percentIncrease}%
            </p>
          </div>
          <div className="mx-4">
            <p className="heading-4">
              Increase in <br /> Traffic
            </p>
          </div>
        </div>
        <div>
          <p>
            There’s been a magnificent increase in both merchant and agent
            traffic over the past week.
            <br />
            <br />
            Congrats Champ!
          </p>
        </div>
      </div>
    </InsightChartWrapper>
  );
}

export default InsightChart;
