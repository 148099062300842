import { useState } from "react";
import CustomOTPInput from "utils/inputBox/CustomOTPInput/CustomOTPInput";
import { CENTER, ERROR } from "utils/helpers/constants";
import Modal from "utils/Modal/Modal";
import { ReactComponent as SecuritySafe } from "assets/images/icons/security-safe.svg";
import { useMutation } from "react-query";
import API from "api";
import { toastHandler } from "utils/helpers/toast";
import { disableAppAuth, verifyDisable2fa } from "api/auth";
import CustomSpinner from "utils/Spinner/Spinner";

function OTPModal({ toggle, title, refetch, user }) {
  const [step, setStep] = useState(0);
  const [OTP, setOTP] = useState();
  function gotoNext() {
    mutate();
  }

  const { mutate, isLoading } = useMutation(
    async () => {
      const response = await API.post(
        user["auth_app"] ? disableAppAuth : verifyDisable2fa,
        {
          token: OTP,
        }
      );
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 200 && data?.success) {
          refetch();
          setStep(step + 1);
        }
      },
      onError: (error) => {
        toastHandler("Unable to proceed", ERROR);
      },
    }
  );
  return (
    <Modal
      title={title}
      subtitle="Permission Request"
      toggle={toggle}
      position={CENTER}
    >
      <div className="px-4 py-50 mx-4">
        {step === 0 && (
          <>
            <h3 className="text-center mt-5 fw-600 ">Enter OTP</h3>
            <h5 className="text-center mb-5 fw-600 text--grey">
              Enter the 6-digit code sent to your Mobile Number and Email
            </h5>
            <CustomOTPInput
              name="otp"
              onChange={(value) => setOTP(value)}
              value={OTP}
              separator="-"
            />
            <button className="btn btn--primary px-4" onClick={gotoNext}>
              {isLoading ? <CustomSpinner /> : "Next"}
            </button>
          </>
        )}
        {step === 1 && (
          <>
            <div className="flex__center">
              <SecuritySafe />
            </div>
            <h3 className="text-center mt-5 fw-600 ">
              Multifactor Authentication Disabled
            </h3>
            <h5 className="text-center mb-5 fw-600 text--grey">
              Your account is less secured 😢
            </h5>
            <button className="btn btn--primary px-4" onClick={toggle}>
              Got it
            </button>
          </>
        )}
      </div>
    </Modal>
  );
}

export default OTPModal;
