export const loadingOption = {
  text: "Updating Chart...",
  color: "#17568BAA",
  textColor: "#17568BAA",
  maskColor: "#FFFFFFCC",
};

export const chartDefaults = {
  grid: {
    bottom: 0,
    containLabel: true,
    left: 0,
    right: 0,
    top: 0,
  },
  textStyle: {
    color: "#000000",
    fontFamily: "gilroy-regular",
    fontSize: 11,
    fontWeight: 500,
    overflow: "truncate",
    lineOverflow: "truncate",
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      z: 10,
    },
  },
};

export const totalComChartOptions = (chartData) => {
  return {
    ...chartDefaults,
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
        "",
      ],
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
    },
    yAxis: {
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      type: "value",
    },
    series: [
      {
        data: [
          0, 420, 932, 401, 934, 1690, 1330, 120, 520, 932, 901, 834, 0,
        ],
        type: "bar",
        showSymbol: false,
        barWidth: 14,
        itemStyle: {
          borderRadius: 4,
          color: "rgba(91, 152, 210, 0.4)",
        },
      },
      {
        data: [
          0, 820, 332, 901, 534, 1290, 330, 1320, 820, 432, 401, 934, 0,
        ],
        type: "bar",
        showSymbol: false,
        barWidth: 14,
        itemStyle: {
          borderRadius: 4,
          color: "rgba(91, 152, 210, 1)",
        },
      },
    ],
    color: "rgba(91, 152, 210, 0.4)",
  };
};
