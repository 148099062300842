// HEADINGS


export const pricesTableHeadings = [
  "Services",
  "Country",
  "Country Code",
  "Charge Type",
  "Value",
  "Created At",
];
export const pricesReportHeadings = {
  priceModelName: "Services",
  country: "Country",
  countryCode: "Country Code",
  type: "Charge Type",
  value: "Value",
  price: "Price",
  createdAt: "Created At",
};

export const pricesFiltersDefaultValues = {
  "status[0]": null,
  "status[1]": null,
  "status[2]": null,
  "action[0]": null,
  "action[1]": null,
};

// TABLE DATA

