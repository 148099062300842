import styled from "styled-components";

export const NavlinkWrapper = styled.li`
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 15px;
  list-style-type: none;
  ${(props) => props.isActive && "background: var(--secondary);"}
  a {
    text-decoration: none;
    font-size: 14px;
    line-height: 34px;
    /* identical to box height, or 243% */

    color: var(--gray);
    ${(props) => props.isActive && "color: #ffffff;"}
    display: flex;
    align-items: center;
    font-weight: 600;
    span {
      margin-left: 10px;
    }
  }
  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;
