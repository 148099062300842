import { getRowSize, storeRowSize } from "storage/sessionStorage";
import SmallSelect from "utils/select/SmallSelect/SmallSelect";

const options = [20, 30, 50, 100, 200, 300, 400, 500, 1000];

const PageSizeSelect = ({ onSizeChange }) => {
	const currentSize = getRowSize();

	const handleSelect = ({ target: { value } }) => {
		storeRowSize(value);
		onSizeChange(value);
	};

	return (
    <div
      className="d-flex align-items-center fw-bold "
      style={{ width: "70px" }}
    >
      <span className="me-2">Showing</span>

      <SmallSelect
        name="size"
        options={options}
        onChange={handleSelect}
        value={currentSize}
      />

      <span className="ms-2">Rows</span>
    </div>
  );
};

export default PageSizeSelect;
