import styled from "styled-components";
import MenuItem from "@mui/material/MenuItem";

export const CustomMenuItem = styled(MenuItem)`
	&.MuiMenuItem-root {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #0e1b28;
		font-family: "gilroy-regular";
		min-width: 200px;
		font-weight: 600;
		color: ${(props) => props.color};
	}
`;

export const DropMenuStyles = styled.div`
	padding: 0px 22px 0 28px;
	.h2 {
		font-size: 20px;
		line-height: 24px;
		color: #0e1b28;
		font-family: "gilroy-semibold";
		margin-top: 21px;
	}
	.body-text {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: #39444f;
		margin: 13px 0 21px;
	}
`;
