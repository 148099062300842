import API from "api";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import SectionWrapper from "utils/section/SectionWrapper/SectionWrapper";
import { ReactComponent as Copy } from "assets/images/icons/copy.svg";
import { useQuery } from "react-query";
import { SUCCESS } from "utils/helpers/constants";
import { getSingleMerchant } from "api/merchant";
import BigSpinner from "utils/Spinner/BigSpinner";
import { timeDateFormat } from "utils/helpers";
import { toastHandler } from "utils/helpers/toast";

function MerchantDetails() {
  const { id } = useParams();
  const route = "/merchants";
  const tabs = [
    { label: "Profile", route: `${route}/profile/${id}` },
    { label: "Wallet Transactions", route: `${route}/transactions/${id}` },
  ];

  const getMerchantDetailQuery = useQuery(
    ["merchantDetailHistory"],
    async () => {
      const response = await API.get(getSingleMerchant, {
        params: { merchantId: id },
      });
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const handleCopy = () => {
    navigator.clipboard.writeText(
      `Bank Name: ${data?.data?.bank?.bankName}, Bank Account Name: ${data?.data?.bank?.accountName}, Bank Account Number: ${data?.data?.bank?.accountNumber}`
    );
    toastHandler("Bank details copied successfully!", SUCCESS);
  };

  const { isLoading, data } = getMerchantDetailQuery;
  const { date, time } = timeDateFormat(data?.data?.user?.createdAt);
  return (
    <SectionWrapper
      goBackRoute={{ name: "Merchants", to: "/merchants" }}
      tabs={tabs}
      routeName="Merchant Information"
    >
      {isLoading ? (
        <div className="w-100 h-500 position-relative flex__column flex__center">
          <BigSpinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-6 p-3">
            <div className="bg-white p-5 rounded-card">
              <p className="text--grey">Representative Information</p>
              <hr className="text--grey mb-4" />
              <div className="row fw-600">
                <>
                  <div className="col-4 text--grey">First Name</div>
                  <div className="col-8 mb-4">
                    {data?.data?.user?.firstName}
                  </div>
                </>
                <>
                  <div className="col-4 text--grey">Last Name</div>
                  <div className="col-8 mb-4">{data?.data?.user?.lastName}</div>
                </>
                <>
                  <div className="col-4 text--grey">Phone Number</div>
                  <div className="col-8 mb-4">
                    {data?.data?.user?.phoneNumber}
                  </div>
                </>
                <>
                  <div className="col-4 text--grey">Email Address</div>
                  <div className="col-8 mb-4">{data?.data?.user?.email}</div>
                </>
                <>
                  <div className="col-4 text--grey">Country</div>
                  <div className="col-8 mb-4">{data?.data?.user?.country}</div>
                </>
                <>
                  <div className="col-4 text--grey">Last Update</div>
                  <div className="col-8 mb-4">
                    {date} {time}
                  </div>
                </>
              </div>
            </div>
          </div>
          <div className="col-6 p-3">
            <div className="bg-white p-5 rounded-card">
              <p className="text--grey">Company Information</p>
              <hr className="text--grey mb-4" />
              <div className="row fw-600">
                <>
                  <div className="col-4 text--grey">Company Name</div>
                  <div className="col-8 mb-4">{data?.data?.businessName}</div>
                </>
                <>
                  <div className="col-4 text--grey">Business Type</div>
                  <div className="col-8 mb-4">{data?.data?.businessType}</div>
                </>
                <>
                  <div className="col-4 text--grey">Company Phone Number</div>
                  <div className="col-8 mb-4">
                    {data?.data?.companyInformation?.companyPhoneNumber ||
                      "N/A"}
                  </div>
                </>
                <>
                  <div className="col-4 text--grey">Email Address</div>
                  <div className="col-8 mb-4">
                    {data?.data?.companyInformation?.companyEmail || "N/A"}
                  </div>
                </>
                <>
                  <div className="col-4 text--grey">Address</div>
                  <div className="col-8 mb-4">
                    {data?.data?.companyInformation?.companyAddress}
                  </div>
                </>
                <>
                  <div className="col-4 text--grey">City</div>
                  <div className="col-8 mb-4">
                    {data?.data?.companyInformation?.city || "N/A"}
                  </div>
                </>
                <>
                  <div className="col-4 text--grey">State</div>
                  <div className="col-8 mb-4">
                    {data?.data?.companyInformation?.state}
                  </div>
                </>
                <>
                  <div className="col-4 text--grey">Country</div>
                  <div className="col-8 mb-4">{data?.data?.country}</div>
                </>
              </div>
            </div>
          </div>
          {data?.data?.bank?.accountName && (
            <div className="col-6 p-3">
              <div className="bg-white p-5 rounded-card">
                <div className="flex__between m-0">
                  <p className="text--grey m-0">Wallet Information</p>
                  <span
                    className="copy-div bg-secondary-accent-theta px-3 py-2 m-0 pointer"
                    onClick={handleCopy}
                  >
                    Copy Details <Copy />
                  </span>
                </div>
                <hr className="text--grey mb-4" />
                <div className="row fw-600">
                  <>
                    <div className="col-4 text--grey">Bank Name</div>
                    <div className="col-8 mb-4">
                      {data?.data?.bank?.bankName}
                    </div>
                  </>
                  <>
                    <div className="col-4 text--grey">Bank Account Name</div>
                    <div className="col-8 mb-4">
                      {data?.data?.bank?.accountName}
                    </div>
                  </>
                  <>
                    <div className="col-4 text--grey">Bank Account Number</div>
                    <div className="col-8 mb-4">
                      {data?.data?.bank?.accountNumber}
                    </div>
                  </>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </SectionWrapper>
  );
}

export default MerchantDetails;
