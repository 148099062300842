export const dayOptions = ["Week","Year"];

export const transactionsTableHeadings = [
  "User",
  "Transaction ID",
  "Amount",
  "Balance",
  "Category",
  "Status",
  "Created At",
];
export const transactionsReportHeadings = {
  user: "User",
  transactionId: "Transaction ID",
  amount: `Amount`,
  currency: "Curency",
  category: "Category",
  status: "Status",
  createdAt: "Date Created",
};