// STATUS CONSTANTS

export const PAID = "PAID";
export const DRAFT = "DRAFT";
export const ACTIVE = "ACTIVE";
export const INACTIVE = "INACTIVE";
export const PENDING = "PENDING";
export const WARNING = "WARNING";
export const SUCCESS = "SUCCESSFUL";
export const COMPLETED = "COMPLETED";
export const SETTLED = "SETTLED";
export const AWAITING = "AWAITING";
export const RESOLVED = "RESOLVED";
export const ESCALATED = "ESCALATED";
export const DANGER = "DANGER";
export const ERROR = "ERROR";


// TRANSACTION CONSTANTS

export const CREDIT = "CREDIT";
export const DEBIT = "DEBIT";
export const BANK_TRANSFER = "BANK-TRANSFER";
export const TRANSFER_METHOD = "TRANSFER_METHOD";
export const VIRTUAL_ACCOUNT = "Virtual Account";

export const DATE_FILTERS = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  CUSTOM: "Custom",
};

// TAB CONSTANTS

export const GRAY = "GRAY";
export const UNDERLINED = "UNDERLINED";

// POSITION CONSTANTS

export const CENTER = "CENTER";

// MODAL CONSTANTS

export const SHORT = "SHORT";
export const NORMAL = "NORMAL";
export const PRIMARY = "PRIMARY";
export const HEADLESS = "HEADLESS";
export const SECONDARY = "SECONDARY";
export const TETIARY = "TETIARY";
export const MID_HEIGHT = "MID_HEIGHT";
export const TRANSFER_CENTER = "TRANSFER_CENTER";
  

export const amountCurrencyOptions = ["USD", "GBP", "EUR"];

export const TOKEN_KEY = "bit_partners_token";
export const PAGE_INFO = "bit_partners_page_info";

export const CONTACT_US_LINK = "https://bluintouch.com/contact-us"
export const PRIVACY_POLICY_LINK = "https://bluintouch.com/privacy-policy"