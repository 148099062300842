import React from "react";
import { Modal as BootstrapModal, ModalBody, ModalFooter } from "reactstrap";
import ModalHeader from "./ModalHeader/ModalHeader";
import { CENTER, PRIMARY } from "utils/helpers/constants";

const Modal = ({
  steps,
  index,
  aside,
  asideCls,
  show = true,
  type = PRIMARY,
  position,
  toggle,
  bodyCls,
  children,
  id,
  footerChildren,
  cls,
  contentCls,
  title,
  subtitle,
  size = "md",
  onEnter,
  onHide,
}) => {
  const isMainSectionWithAside = aside ? "col-12 col-lg-8" : "col-12";
  const isWithFooterChildrenOrAside = footerChildren || aside ? "" : "px-0";
  const isCenter = position === CENTER ? "center-modal " : "";

  return (
    <BootstrapModal
      id={id}
      className={`${isCenter} ${cls}`}
      contentClassName={contentCls}
      isOpen={show}
      size={size}
      onEnter={onEnter}
      // onHide={onHide}
    >
        {type && (
          <ModalHeader
            steps={steps}
            index={index}
            type={type}
            toggle={toggle}
            title={title}
            subtitle={subtitle}
          />
        )}
        <ModalBody
          className={`d-flex flex-wrap  ${isWithFooterChildrenOrAside} ${bodyCls}`}
        >
          {aside && (
            <aside
              className={`col-12 col-lg-4 px-5 ps-lg-0 ${asideCls}`}
              id="modal--aside-section"
            >
              {aside}
            </aside>
          )}
          <div className={isMainSectionWithAside} id="modal--main-section">
            {children}
          </div>
        </ModalBody>
        {footerChildren && <ModalFooter>{footerChildren}</ModalFooter>}
    </BootstrapModal>
  );
};

export default Modal;
