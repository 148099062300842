import { CENTER } from "utils/helpers/constants";
import Modal from "utils/Modal/Modal";
import { ReactComponent as Avatar } from "assets/images/icons/fine-boy-lg.svg";

const AuditTrailDetailModal = ({ toggle, data }) => {
  console.log({ data });
  return (
    <Modal
      title="Audit Log"
      //   subtitle="Audit Log"
      toggle={toggle}
      position={CENTER}
    >
      <div className="bg-secondary-accent-theta py-50 flex__center flex__column">
        <Avatar />
        <br />
        <h3>{data?.name}</h3>
        <span className="text--grey">{data?.company}</span>
        <span className="pill--completed mt-2">{data?.activity}</span>
      </div>
      <div className="px-4 py-50 mx-4">
        <div className="row fw-600">
          <>
            <div className="col-12 mb-4">Audit Details</div>
          </>
          <>
            <div className="col-4 text--grey">Phone Number</div>
            <div className="col-8 mb-4">+234 904 860 5964</div>
          </>
          <>
            <div className="col-4 text--grey">Email Address</div>
            <div className="col-8 mb-4">yemi@blusalt.net</div>
          </>
          <>
            <div className="col-4 text--grey">Event</div>
            <div className="col-8 mb-4">
              <span className="pill--completed mt-2">{data?.activity}</span>
            </div>
          </>
          <>
            <div className="col-4 text--grey">IP Address</div>
            <div className="col-8 mb-4">192.113.20.131</div>
          </>
          <>
            <div className="col-4 text--grey">Device</div>
            <div className="col-8 mb-4">Google Chrome, OS X, v15.5</div>
          </>
          <>
            <div className="col-4 text--grey">Date</div>
            <div className="col-8 mb-4">24 March, 2022</div>
          </>
          <>
            <div className="col-4 text--grey">Time</div>
            <div className="col-8 mb-4">11:43:19 am WAT5</div>
          </>
          <>
            <div className="col-4 text--grey">Number of Attempts</div>
            <div className="col-8 mb-4">1</div>
          </>
          <>
            <div className="col-4 text--grey">Company Name</div>
            <div className="col-8 mb-4">Blusalt</div>
          </>
        </div>
      </div>
    </Modal>
  );
};

export default AuditTrailDetailModal;
