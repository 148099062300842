
import { CENTER } from "utils/helpers/constants";
import Modal from "utils/Modal/Modal";

function BlockTeamModal({ toggle, title }) {
  
  return (
    <Modal
      title="Block Member Access"
      subtitle="Revoke members access and roles"
      toggle={toggle}
      position={CENTER}
    >
      <div className="px-4 py-5 mx-4">
        <h3 className="text-center mt-5 fw-600 ">
          You are about to block member’s access?
        </h3>
        <h5 className="text-center mb-5 fw-600 text--grey">
          Are you sure you want to block this member’s access to dashboard?This
          member won’t be able to access dashboard information
        </h5>
        <div className="d-flex">
          <button className="btn btn--danger px-4" onClick={toggle}>
            Yes, Block Access
          </button>
          <button className="btn btn--primary--bordered px-4 ms-4" onClick={toggle}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default BlockTeamModal;
