import React, { useState } from "react";
import { LoginWrapper } from "./LoginWrapper";
import { formValidator } from "utils/FormValidator/FormValidator";
import blackMadam from "assets/images/black-madam.jpg";
import { ReactComponent as Logo } from "assets/images/icons/bluintouch-logo-dark.svg";
import { ReactComponent as Mtn } from "assets/images/icons/MTN_Logo.svg";
import { ReactComponent as Airtel } from "assets/images/icons/airtel-logo.svg";
import { ReactComponent as Zenith } from "assets/images/icons/zenith-logo.svg";
import { ReactComponent as Ecobank } from "assets/images/icons/ecobank.svg";
import { ReactComponent as LeadRemit } from "assets/images/icons/lead-remit.svg";
import { Link } from "react-router-dom";
import CustomInputBox from "utils/inputBox/CustomInputBox/CustomInputBox";
import API from "api";
import { useMutation } from "react-query";
import { forgotPassword } from "api/auth";
import CustomSpinner from "utils/Spinner/Spinner";
import {
  CONTACT_US_LINK,
  ERROR,
  PRIVACY_POLICY_LINK,
  SUCCESS,
} from "utils/helpers/constants";
import { toastHandler } from "utils/helpers/toast";
import { titleCase } from "utils/helpers";

const ForgotPassword = () => {
  const [formValues, setFormValues] = useState({
    email: ""
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = ({ target: { name, value } }) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  function handleSubmit(e) {
    e.preventDefault();
    const { formErrors, isValid } = formValidator({ email: formValues.email });
    isValid ? mutate() : setFormErrors(formErrors);
  }

  const { mutate, isLoading } = useMutation(
    async () => {
      const payload = {
        ...formValues,
      };
      const response = await API.post(forgotPassword, payload);
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 200 && data?.success) {
          toastHandler(titleCase(data?.data?.success), SUCCESS);
          setFormValues((prev) => ({
            ...prev,
            email: "",
          }));
        } 
      },
      onError: (error) => {
        toastHandler(titleCase(error?.data?.data?.error), ERROR);
      },
    }
  );

  return (
    <LoginWrapper className=" d-flex">
      <div className="left-div">
        <div className="auth-nav flex__between">
          <Logo className="pointer" />
          <div className="auth-nav-links d-flex">
            <a
              href={PRIVACY_POLICY_LINK}
              to={PRIVACY_POLICY_LINK}
              target="_blank"
              rel="noreferrer"
              className="mx-4"
            >
              Privacy Policy
            </a>
            <a
              to={CONTACT_US_LINK}
              target="_blank"
              rel="noreferrer"
              href={CONTACT_US_LINK}
            >
              Contact Us
            </a>
          </div>
        </div>
        <div className="login-form">
          <div className="px-5 py-4">
          </div>
          <h2 className="m-0 p-0">Forgot Password?</h2>
          <p className="m-0 text--md">Enter your email to continue</p>
          <form onSubmit={handleSubmit}>
            <CustomInputBox
              placeholder="yemi@blusalt.net"
              error={formErrors?.email}
              name="email"
              onChange={handleChange}
              value={formValues?.email}
            />
            <button className="btn btn--primary btn--lg" type="submit">
              {isLoading ? <CustomSpinner /> : "Submit"}
            </button>
            <p className="mt-5 heading-3">
              Already have an account?{" "}
              <Link className="text--secondary" to="/login">
                Login
              </Link>
            </p>
          </form>
        </div>

        <div className="partners-div p-5 rounded flex__between">
          <Mtn />
          <Airtel className="mx-2" />
          <Zenith className="mx-3" />
          <Ecobank className="mx-3" />
          <LeadRemit />
        </div>
        <div>
          <p className="mt-5">© Blusalt Financial Service Ltd. </p>
        </div>
      </div>
      <div className="right-img-div">
        <img src={blackMadam} alt="black madam" />
      </div>
    </LoginWrapper>
  );
};

export default ForgotPassword;
