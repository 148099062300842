import {useRef} from 'react';
import {useEffect, useState} from 'react';
import {startCase, lowerCase, kebabCase, uniqueId} from 'lodash';
import {truncateText} from 'utils/helpers';
import { ReactComponent as InfoCircleErrorIcon } from "assets/images/icons/info-circle-error.svg";
import { ReactComponent as RedMinusSquareIcon } from "assets/images/icons/close-circle.svg";
import { ReactComponent as DocuemntIcon } from "assets/images/icons/document.svg";

export const FileUploadSelect = ({
    accept,
    type = 'file',
    name,
    children,
    label,
    btnCls,
    cls,
    inputCls,
    uploadMulti,
    value,
    infoText,
    required,
    title,
    description,
    placeholder,
    readOnly,
    onChange,
    closeIconClick,
    handleSelect,
    hideFilesList,
    error,
    disabled,
    showInput,
    selectMulti,
    files,
    fileClass = '',
    ...rest
}) => {
    const fileUpload = useRef(null);
    const [currentFiles, setCurrentFiles] = useState(files);

    const isError = error ? ' error' : '';
    const isShowInput = showInput ? '' : 'd-none';
    const isRequired = required ? ' required' : '';
    const isDisabled = disabled ? ' disabled' : '';

    useEffect(() => {
        fileUpload?.current?.value && handleSelect(name, {...currentFiles});
        // eslint-disable-next-line
    }, [currentFiles]);

    const handleFileChange = e => {
        e.preventDefault();
        // eslint-disable-next-line no-unused-expressions
        !disabled ? fileUpload.current.click() : null;
    };

    const handleFileSelect = ({target: {files}}) => {
        if (Object.keys(files).length > 0) {
            // eslint-disable-next-line array-callback-return
            Object.keys(files)?.map(key => {
                files[key].id = uniqueId();
                files[key].fileClass = startCase(lowerCase(fileClass));
                uploadMulti
                    ? setCurrentFiles(prev => [...prev, files[key]])
                    : setCurrentFiles([files[key]]);
            });
        }
    };

    const handleFileDelete = id => {
        const fileIndex = currentFiles.findIndex(file => file.id === id);
        currentFiles.splice(fileIndex, 1);
        setCurrentFiles([...currentFiles]);
    };

    return (
            <div className={`form-group`}>
                <div className={`input-wrapper file-upload no-hover-color ${cls}`}>
                    <div className={isShowInput}>
                        <label
                            className={`form-group-label ${isRequired}`}
                            htmlFor={kebabCase(name)}
                        >
                            {label}
                        </label>
                        <div className={`input-wrapper${isDisabled}${isError}`}>
                            <input
                                className="pointer file-input w-100"
                                id={kebabCase(name)}
                                placeholder={placeholder}
                                readOnly={readOnly}
                                autoComplete="off"
                                accept={accept}
                                type={type}
                                ref={fileUpload}
                                name={name}
                                label={label}
                                onChange={handleFileSelect}
                                disabled={disabled}
                                // error={error}
                                multiple={selectMulti}
                                value={value}
                                {...rest}
                            />
                        </div>
                    </div>

                    {children && (
                        <label
                            className={btnCls}
                            htmlFor={kebabCase(name)}
                            onClick={handleFileChange}
                        >
                            {children}
                        </label>
                    )}
                    {files && !hideFilesList && (
                        <div className="mt-4">
                            {typeof files === 'object' &&
                                files &&
                                Object.keys(files)?.map((key, i) => (
                                    <File
                                        key={i}
                                        name={truncateText(files[key]?.name, 20)}
                                        fileClass={truncateText(files[key]?.fileClass, 25)}
                                        closeIconClick={() => handleFileDelete(files[key].id)}
                                    />
                                ))}
                        </div>
                    )}
                </div>
                {infoText && <div className="pt-3 w-100 infoText">{infoText}</div>}
                {typeof error === 'string' && error && (
                    <small className="input-text-error mt-2">
                        <InfoCircleErrorIcon className="me-2" />
                        {error}
                    </small>
                )}
            </div>
    );
};

const File = ({name, fileClass, closeIconClick}) => (
    <p className="flex__between flex-wrap fw-bold border--gray p-3">
        <span className={`flex__start ${fileClass ? 'mb-3 mb-sm-0 col-12 col-sm-6' : ''}`}>
            <RedMinusSquareIcon className="pointer me-5" onClick={closeIconClick} />
            {fileClass && <span className="auth-text-gray mt-1">{fileClass}</span>}
        </span>
        <span
            className={`stroke-gradient d-flex justify-content-sm-end align-items-center ${
                fileClass ? 'col-12 col-sm-6' : ''
            }`}
        >
            <DocuemntIcon className="me-2" />
            {name}
        </span>
    </p>
);
