import { ReactComponent as RefreshIcon } from "assets/images/icons/refresh.svg";

const FilterWrapper = ({
	id,
	isApplied,
	disabled,
	handleFilter,
	clearFilter,
	children,
  cls
}) => {
	const handleSubmit = (e) => {
		e.preventDefault();
		handleFilter();
	};
	return (
		<>
			<form
				onSubmit={handleSubmit}
				className={`table-filter shadow ${cls}`}
				id={id}
			>
				<div id="filter-form">{children}</div>
				<div id="filter-footer">
					{isApplied && (
						<button
							type="button"
							className="btn btn--light-gray--bordered btn--sm me-1"
							onClick={clearFilter}
						>
							<RefreshIcon />
						</button>
					)}

					<button
						type="submit"
						disabled={disabled}
						className="btn btn--primary btn--sm ms-2"
					>
						Save Filter
					</button>
				</div>
			</form>
		</>
	);
};

export default FilterWrapper;
