// HEADINGS

export const commissionsTableHeadings = [
  "Services",
  "Cost",
  "Commissions",
  "Created At",
];

export const commissionsReportHeadings = {
  serviceName: "Services",
  price: "Cost",
  commission: "Commissions",
  createdAt: "Created At",
};

// TABLE DATA

export const dayOptions = ["Daily", "Monthly", "Yearly"];
