import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import StyledDateSelect from "utils/select/DateSelect/StyledDateSelect";
import { dayOptions } from "../../data";
import { loadingOption, totalComChartOptions } from "../../helpers";
import { ReactComponent as InfoCircleGray } from "assets/images/icons/info-circle-gray.svg";

function WalletDetailsLeftChart() {
  const [dateRange, setDateRange] = useState("Today");
  let timer;

  useEffect(() => {
    return () => clearTimeout(timer);
  });

  function onChartReady(echarts) {
    timer = setTimeout(function () {
      echarts.hideLoading();
    }, 3000);
  }
  return (
    <div className="col-md-6 p-4 pl-0">
      <div className="bg-white px-0 rounded-card">
        <div className="flex__between p-5 ">
          <div>
            <p className="text--grey heading-4">
              Average Transactions{" "}
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-right">
                    Total Amount of money that was paid into your wallet. This
                    is the sum of all payments plus charges
                  </Tooltip>
                }
              >
                <span className="d-inline-block">
                  <InfoCircleGray className="ms-2 pointer" />
                </span>
              </OverlayTrigger>
            </p>
            <p className="black text--xl text-break">N1,243,435</p>
          </div>
          <StyledDateSelect
            wrapperCls="col-5 col-sm-2"
            cls="col-9 col-sm-10"
            name="date-select"
            formLabel="date-select"
            onChange={(e) => setDateRange(e)}
            options={dayOptions}
            value={dateRange}
          />
        </div>
        <ReactECharts
          loadingOption={loadingOption}
          onChartReady={onChartReady}
          option={totalComChartOptions(null)}
          opts={{ renderer: "svg" }}
          showLoading={true}
          style={{
            height: "180px",
            minWidth: "100%",
            margin: "auto",
            paddingBottom: "20px",
          }}
        />
      </div>
    </div>
  );
}

export default WalletDetailsLeftChart;
