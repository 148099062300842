import SmallSelect from "utils/select/SmallSelect/SmallSelect";
import { StyledDateSelectWrapper } from "./StyledDateSelectWrapper";

export default function StyledDateSelect({
  cls,
  wrapperCls,
  onChange,
  endDate,
  formLabel,
  label,
  name,
  options,
  value,
}) {
  return (
    <>
      <StyledDateSelectWrapper>
        <SmallSelect
          name={name}
          label={label}
          formLabel={formLabel}
          onChange={({ target: { value } }) => onChange(value)}
          options={options}
          value={value}
        />
      </StyledDateSelectWrapper>
    </>
  );
}
