import { useContext, useState } from "react";
import SectionWrapper from "utils/section/SectionWrapper/SectionWrapper";
import { tabs } from "../data";
import ChangePasswordModal from "../modals/ChangePasswordModal";
import { AuthContext } from "utils/context/AuthContext";
import OTPModal from "../modals/OTPModal";
import { useMutation } from "react-query";
import API from "api";
import { enableAppAuth, toggle2fa } from "api/auth";
import { toastHandler } from "utils/helpers/toast";
import { ERROR, SUCCESS } from "utils/helpers/constants";
import CustomSpinner from "utils/Spinner/Spinner";
import AppAuthenticatorModal from "../modals/AppAuthenticatorModal";

const Security = () => {
  const userProfile = useContext(AuthContext);
  const { user } = userProfile;
  const [showModal, setShowModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showAppOtpModal, setShowAppOtpModal] = useState(false);
  const toggle = () => {
    setShowModal((prev) => !prev);
  };
  const toggleOtp = () => {
    setShowOtpModal((prev) => !prev);
  };
  const toggleAppOtp = () => {
    setShowAppOtpModal((prev) => !prev);
  };


  const { mutate, isLoading } = useMutation(
    async () => {
      const response = await API.post(toggle2fa, {
        set2FA: !user["2FA"],
      });
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 200 && data?.success) {
          !user["2FA"] && toastHandler("MFA enabled successfully", SUCCESS);
          userProfile?.refetch();
        }
      },
      onError: (error) => {
        toastHandler(error.data.data.error, ERROR);
      },
    }
  );
  const disableMFAMutation = useMutation(
    async () => {
      const response = await API.post(enableAppAuth, {
        set2FA: false,
      });
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 200 && data?.success) {
          userProfile?.refetch();
        }
      },
      onError: (error) => {
        toastHandler(error.data.data.error, ERROR);
      },
    }
  );

  const setMFA = () => {
    if (user["2FA"]) {
      mutate();
      toggleOtp();
    } else {
      mutate();
    }
  };
  const setAppAuth = () => {
    if (user["auth_app"]) {
      disableMFAMutation.mutate();
      toggleOtp();
    } else {
      toggleAppOtp();
    }
  };
  return (
    <>
      <SectionWrapper
        routeName="Security"
        tabs={tabs}
        headerChildren={
          <button className="btn btn--primary" onClick={toggle}>
            Change Password
          </button>
        }
      >
        <div className="p-5 bg-white rounded-card">
          <h4 className="text--grey fw-600">Security Information</h4>
          <hr className="text--grey mb-5" />

          <div className="col-7">
            <div className="flex__between mb-4">
              <div>
                <p>Change Password</p>
                <p className="text--grey">
                  Update your secret combination of letters and numbers
                </p>
              </div>
              <div>
                <button className="btn btn--primary px-4" onClick={toggle}>
                  Change Password
                </button>
              </div>
            </div>
            <div className="flex__between mb-4">
              <div>
                <p>Multi-Factor Authentication</p>
                <p className="text--grey">
                  Set up Multi Factor Authentication to secure your account
                </p>
              </div>
              <div>
                <button
                  className="btn btn--primary--bordered px-4"
                  onClick={setMFA}
                >
                  {isLoading ? (
                    <CustomSpinner />
                  ) : (
                    <>{user && user["2FA"] ? "Disable" : "Enable"}</>
                  )}
                </button>
              </div>
            </div>
            <div className="flex__between mb-4">
              <div>
                <p>Authenticator App</p>
                <p className="text--grey">
                  Generate an OTP through an authenticator app on your device
                </p>
              </div>
              <div>
                <button
                  className="btn btn--primary--bordered px-4"
                  onClick={setAppAuth}
                >
                  {user && user["auth_app"] ? "Disable" : "Enable"}
                </button>
              </div>
            </div>
          </div>
        </div>
        {showOtpModal && (
          <OTPModal
            toggle={toggleOtp}
            refetch={userProfile?.refetch}
            user={user}
          />
        )}
        {showAppOtpModal && (
          <AppAuthenticatorModal
            toggle={toggleAppOtp}
            user={user}
            refetch={userProfile?.refetch}
          />
        )}
        {showModal && <ChangePasswordModal toggle={toggle} />}
      </SectionWrapper>
    </>
  );
};

export default Security;
