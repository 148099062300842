import { Country } from "country-state-city";
import CustomInputBoxDropdown from "utils/inputBox/CustomInputBoxDropdown/CustomInputBoxDropdown";

const CustomPhoneNumber = ({
  name,
  label,
  labelIcon,
  placeholder,
  value,
  readonly,
  error,
  hide,
  icon,
  iconRight,
  infoText,
  inputLeft,
  onChange,
  onFocus,
  required,
  length,
  question,
  cls,
  disabled,
  action,
  secondary,
  dropdownID,
  dropDownName,
  dropDownValue,
  ...rest
}) => {
  const countries = Country.getAllCountries();

  const parsedCountryCodes = countries.map((country) => {
    const phoneCode = `${country.phonecode?.startsWith("+") ? "" : "+"}${
      country?.phonecode
    }`;
    return {
      label: `${country?.flag} (${phoneCode})`,
      value: phoneCode,
      ...country,
    };
  });

  const nigeria = countries.find((country) => country.isoCode === "NG");
  const placeholderText = `${nigeria.flag} +${nigeria.phonecode}`;
  return (
    <CustomInputBoxDropdown
      name={name}
      label={label}
      labelIcon={labelIcon}
      placeholder={placeholder}
      value={value}
      readonly={readonly}
      error={error}
      hide={hide}
      icon={icon}
      iconRight={iconRight}
      infoText={infoText}
      inputLeft={inputLeft}
      onChange={onChange}
      onFocus={onFocus}
      required={required}
      length={length}
      question={question}
      cls={cls}
      disabled={disabled}
      action={(index) => action(dropDownName, parsedCountryCodes[index])}
      secondary={secondary}
      dropdownID={dropdownID}
      dropDownValue={dropDownValue}
      dropDownPlaceholder={placeholderText}
      options={parsedCountryCodes}
      type='number'
      {...rest}
    />
  );
};

export default CustomPhoneNumber;
