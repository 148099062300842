import { useContext, useState } from "react";
import CustomInputBox from "utils/inputBox/CustomInputBox/CustomInputBox";
import CustomPhoneNumber from "utils/inputBox/CustomPhoneNumber/CustomPhoneNumber";
import { CENTER } from "utils/helpers/constants";
import Modal from "utils/Modal/Modal";
import CountrySelect from "utils/select/CountrySelect/CountrySelect";
import CustomSelect from "utils/select/CustomSelect/CustomSelect";
import StateSelect from "utils/select/StateSelect/StateSelect";
import { createMerchantDefaultValues } from "../data";
import { formValidator } from "utils/FormValidator/FormValidator";
import { useMutation, useQuery } from "react-query";
import API from "api";
import { addNewMerchant } from "api/merchant";
import CustomSpinner from "utils/Spinner/Spinner";
import Alert from "utils/Alert/Alert";
import TaskCompletionStep from "utils/TaskCompletionStep/TaskCompletionStep";
import { getBusinessType } from "api/auth";
import { AuthContext } from "utils/context/AuthContext";

function AddMerchantModal({ toggle, refetch }) {
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    ...createMerchantDefaultValues,
  });
  const { id } = useContext(AuthContext);
  const [successMsg, setSuccessMsg] = useState();
  const subHeading = ["Company Information", "Representative Information"];
  const [headerIndex, setHeaderIndex] = useState(0);
  const [errorMsg, setErrorMsg] = useState();
  function gotoNext(e) {
    e.preventDefault();
    const payload = {
      ...formValues,
    };
    delete payload.companyId;
    delete payload.email;
    delete payload.firstName;
    delete payload.lastName;
    delete payload.phoneNumber;
    const { formErrors, isValid } = formValidator(payload);
    isValid ? setHeaderIndex(1) : setFormErrors(formErrors);
  }
  function goBack(e) {
    e.preventDefault();
    setHeaderIndex(0);
  }

  const handleSelect = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));

    if (name === "country") {
      const phoneCode = `${value.phonecode?.startsWith("+") ? "" : "+"}${
        value?.phonecode
      }`;
      setFormValues((prev) => ({
        ...prev,
        state: null,
        phoneCountryCode: {
          ...value,
          label: `${value?.flag} (${phoneCode})`,
        },
      }));
    }

    if (name === "state") {
      setFormValues((prev) => ({
        ...prev,
      }));
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    handleSelect(name, value);
  };

  const { mutate, isLoading } = useMutation(
    async () => {
      setErrorMsg();
      const payload = {
        ...formValues,
        businessType: formValues.businessType.value,
        country: formValues.country.name,
        state: formValues.state.value,
        phoneNumber: `+${formValues.phoneCountryCode.phonecode}${formValues.phoneNumber}`,
        companyId: id,
      };
      delete payload.phoneCountryCode;
      delete payload.phoneCode;
      const response = await API.post(addNewMerchant, payload);
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 200 && data?.data?.success) {
          setSuccessMsg(data?.data?.success);
          refetch()
        } else setErrorMsg(data?.data?.success);
      },
      onError: (error) => {
        setErrorMsg(error?.data?.data?.error);
      },
    }
  );
  function handleSubmit(e) {
    e.preventDefault();
    const payload = {
      ...formValues,
    };
    delete payload.companyId;
    const { formErrors, isValid } = formValidator(payload);
    isValid ? mutate() : setFormErrors(formErrors);
  }
  const getBusinessTypeQuery = useQuery(
    ["businessType"],
    async () => {
      const response = await API.get(getBusinessType);
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Modal
      title="Add Merchant"
      subtitle={subHeading[headerIndex]}
      toggle={toggle}
      position={CENTER}
    >
      <div className="px-5 py-4">
        {errorMsg && <Alert message={errorMsg} onClose={() => setErrorMsg()} />}
      </div>
      {successMsg && (
        <TaskCompletionStep title={successMsg}>
          <div className="px-4 py-4">
            <button
              onClick={() => {
                toggle();
                setSuccessMsg();
              }}
              type="button"
              className="btn btn--sm btn--primary"
            >
              Close
            </button>
          </div>
        </TaskCompletionStep>
      )}
      {!successMsg && (
        <div className="px-4 py-50 mx-4">
          {headerIndex === 0 && (
            <form>
              <CustomInputBox
                label="Business Name"
                placeholder="Enter Business Name"
                error={formErrors?.businessName}
                name="businessName"
                onChange={handleChange}
                value={formValues?.businessName}
              />
              <CustomInputBox
                label="Business Address"
                placeholder="Enter Business Address"
                error={formErrors?.companyAddress}
                name="companyAddress"
                onChange={handleChange}
                value={formValues?.companyAddress}
              />
              <CustomSelect
                name="businessType"
                label="Sector"
                onChange={(value) => handleSelect("businessType", value)}
                options={getBusinessTypeQuery?.data?.data?.map((bank) => ({
                  label: bank.typeName,
                  value: bank.typeName,
                }))}
                placeholder="Select Sector"
                value={formValues?.businessType}
                error={formErrors?.businessType}
              />
              <CountrySelect
                label="Country"
                placeholder="Select Country"
                error={formErrors?.country}
                onChange={(value) => handleSelect("country", value)}
                value={formValues?.country}
              />
              <StateSelect
                label="State"
                placeholder="Select State"
                countryCode={formValues?.country?.isoCode}
                error={formErrors?.state}
                onChange={(value) => handleSelect("state", value)}
                value={formValues?.state}
              />
              <hr className="text--grey my-4" />
              <button className="btn btn--primary px-4" onClick={gotoNext}>
                Next
              </button>
            </form>
          )}
          {headerIndex === 1 && (
            <form>
              <CustomInputBox
                label="First Name"
                placeholder="Enter First Name"
                error={formErrors?.firstName}
                name="firstName"
                onChange={handleChange}
                value={formValues?.firstName}
              />
              <CustomInputBox
                label="Last Name"
                placeholder="Enter Last Name"
                error={formErrors?.lastName}
                name="lastName"
                onChange={handleChange}
                value={formValues?.lastName}
              />
              <CustomInputBox
                label="Email Address"
                placeholder="Enter Email Address"
                error={formErrors?.email}
                name="email"
                onChange={handleChange}
                value={formValues?.email}
              />
              <CustomPhoneNumber
                label="Phone Number"
                dropdownID="phone-code-dropdown"
                dropDownName="phoneCode"
                name="phoneNumber"
                placeholder="Type Here"
                error={formErrors?.phoneNumber}
                onChange={handleChange}
                action={handleSelect}
                dropDownValue={formValues?.phoneCountryCode?.label}
                value={formValues?.phoneNumber}
              />
              <hr className="text--grey my-4" />
              <div className="d-flex">
                <button className="btn btn--primary px-4 mx-3" onClick={goBack}>
                  Prev
                </button>
                <button
                  className="btn btn--primary px-4"
                  onClick={handleSubmit}
                >
                  {isLoading ? <CustomSpinner /> : "Submit"}
                </button>
              </div>
            </form>
          )}
        </div>
      )}
    </Modal>
  );
}

export default AddMerchantModal;
