import API from "api";
import { useContext, useEffect, useState } from "react";
import StyledDateSelect from "utils/select/DateSelect/StyledDateSelect";
import { MerchantChartWrapper } from "./MerchantChartWrapper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as InfoCircleGray } from "assets/images/icons/info-circle-gray.svg";
import { ReactComponent as UserProfile } from "assets/images/icons/profile-icon.svg";
import { ReactComponent as SmileyProfile } from "assets/images/icons/smiley-icon.svg";
import { ReactComponent as Linegraph } from "assets/images/icons/line-graph.svg";
import { loadingOption, totalComChartOptions } from "../../helpers";
import ReactECharts from "echarts-for-react";
import { useQuery } from "react-query";
import { getTotalCommission, getTotalMerchants } from "api/dashboard";
import { AuthContext } from "utils/context/AuthContext";

function MerchantChart() {
  const { id } = useContext(AuthContext);
  const [dateRange, setDateRange] = useState("Today");
  let timer;
  useEffect(() => {
    return () => clearTimeout(timer);
  });
  
  const getTotalMerchantsQuery = useQuery(
    ["totalMerchants", dateRange],
    async () => {
      const response = await API.get(getTotalMerchants, {
        params: {
          companyId: id,
          filter: dateRange.toLowerCase(),
        },
      });
      return response.data.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const getTotalCommissionQuery = useQuery(
    ["totalCommision", dateRange],
    async () => {
      const response = await API.get(getTotalCommission, {
        params: {
          companyId: id,
          period: dateRange.toLowerCase(),
        },
      });
      return response.data.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  function onChartReady(echarts) {
    timer = setTimeout(function () {
      echarts.hideLoading();
    }, 3000);
  }
  return (
    <MerchantChartWrapper className="col-md-8 p-4 pl-0">
      <div className="bg-white rounded-card p-5">
        <div className="row">
          <div className="col-sm-6">
            <div className="flex__between p-4 ">
              <div>
                <p className="text--grey heading-4">
                  Total Merchants{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-right">
                        Total Amount of money that was paid into your wallet.
                        This is the sum of all payments plus charges
                      </Tooltip>
                    }
                  >
                    <span className="d-inline-block">
                      <InfoCircleGray className="ms-2 pointer" />
                    </span>
                  </OverlayTrigger>
                </p>
              </div>
              <StyledDateSelect
                wrapperCls="col-5 col-sm-6"
                cls="col-9 col-sm-10"
                name="date-select"
                formLabel="date-select"
                onChange={(e) => setDateRange(e)}
                options={["Today", "Month", "Year"]}
                value={dateRange}
              />
            </div>
            <div className="row border rounded">
              <div className="col-sm-6 p-4">
                <div className=" p-4 rounded">
                  <UserProfile />
                  <p className="black text--xl text-break mb-0">
                    {getTotalMerchantsQuery?.data?.merchantCount || 0}
                  </p>
                  <p className="text--grey heading-4 mt-0">Merchants </p>
                  <div className="d-flex">
                    <Linegraph />
                    <div>
                      <p className="black text--sm text-break mb-0 text-success fw-bold">
                        {getTotalMerchantsQuery?.data?.percentageIncrease || 0}%
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 p-4">
                <div className="bg-secondary-accent-theta p-4 rounded">
                  <SmileyProfile />
                  <p className="black text--xl text-break mb-0">
                    {getTotalCommissionQuery?.data?.commissionCount}
                  </p>
                  <p className="text--grey heading-4 mt-0">
                    Total Commissions{" "}
                  </p>
                  <div className="d-flex">
                    <Linegraph
                      style={{
                        transform: "rotateX(180deg)",
                      }}
                    />
                    <div>
                      <p className="black text--sm text-break mb-0 text-success fw-bold">
                        {getTotalCommissionQuery?.data?.percentageIncrease || 0}
                        %
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <ReactECharts
              loadingOption={loadingOption}
              onChartReady={onChartReady}
              option={totalComChartOptions(null)}
              opts={{ renderer: "svg" }}
              // showLoading={true}
              style={{
                height: "260px",
                minWidth: "100%",
                margin: "auto",
              }}
            />
          </div>
        </div>
      </div>
    </MerchantChartWrapper>
  );
}

export default MerchantChart;
