import {ReactComponent as BackIcon} from "assets/images/icons/chevron-down.svg";
import {ReactComponent as CloseCircleInvertIcon} from "assets/images/icons/close-circle-invert.svg";
import {ReactComponent as CloseCircleIcon} from "assets/images/icons/close-circle.svg";
import {
  HEADLESS,
  NORMAL,
  PRIMARY,
  SECONDARY,
  TETIARY,
} from "utils/helpers/constants";
import StepPill from "./components/StepPill";

const ModalHeader = ({
	type,
	toggle,
	prevStep,
	title,
	subtitle,
	closeIconCls,
  steps,
  index = 1,
}) => {
	const render = () => {
		switch (type) {
			case HEADLESS:
				return (
					<div className="flex__end px-5">
						<CloseCircleIcon
							className={`pointer ${closeIconCls}`}
							onClick={toggle}
						/>
					</div>
				);

			case NORMAL:
				return (
					<div className="normal__header">
						<div className="col-11">
							<div className="title mb-2">{title}</div>
							{subtitle && (
								<h3 className="subtitle">{subtitle}</h3>
							)}
						</div>
						<CloseCircleIcon
							className={`pointer ${closeIconCls}`}
							onClick={toggle}
						/>
					</div>
				);

			case PRIMARY:
				return (
					<div className="primary__header">
						<div className="col-11">
							<span className="title mb-2">{title}</span>
							<h3 className="subtitle">{subtitle}</h3>
						</div>
						<CloseCircleIcon className="pointer" onClick={toggle} />
					</div>
				);

			case SECONDARY:
				return (
					<div className="text-center pb-4 position-relative">
						{prevStep && (
							<span
								className="abs position-absolute pointer"
								style={{ left: "10px", top: "15px" }}
							>
								<BackIcon height="25px" onClick={prevStep} />
							</span>
						)}
						<p className="heading6 border-bottom pb-4 pt-0">
							{title}
						</p>
						{toggle && (
							<span
								className="abs position-absolute pointer"
								style={{ right: "0px", top: "0px" }}
							>
								<CloseCircleIcon
									height="25px"
									onClick={toggle}
								/>
							</span>
						)}
					</div>
				);

        case TETIARY:
          return (
            <div className="tetiary__header">
              <div className="col-8">
                <p className="title mb-2">{title}</p>
                <h3 className="subtitle">{subtitle}</h3>
                {steps && (
                  <div className="d-flex mt-4 align-items-center">
                    {[...Array(steps)].map((_, i) => (
                      <StepPill key={i} active={i <= index - 1} index={i} />
                    ))}
                  </div>
                )}
              </div>
              <CloseCircleInvertIcon className="pointer" onClick={toggle} />
            </div>
          );

			default:
				return "";
		}
	};

	return <>{render()}</>;
};

export default ModalHeader;
