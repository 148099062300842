import { deleteTeam } from "api/settings";
import { useMutation } from "react-query";
import { CENTER, ERROR, SUCCESS } from "utils/helpers/constants";
import Modal from "utils/Modal/Modal";
import API from "api";
import { toastHandler } from "utils/helpers/toast";
import CustomSpinner from "utils/Spinner/Spinner";

function DeleteTeamModal({ toggle, refetch, data }) {
  const { mutate, isLoading } = useMutation(
    async () => {
      const response = await API.delete(deleteTeam(data.id));
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 200 && data?.success) {
          toastHandler(data?.message, SUCCESS);
          refetch();
          toggle();
        } else toastHandler(data?.message, ERROR);
      },
      onError: (error) => {
        toastHandler(error?.data?.data?.error, ERROR);
      },
    }
  );
  return (
    <Modal
      title="Delete Member"
      subtitle="Delete member permanently"
      toggle={toggle}
      position={CENTER}
    >
      <div className="px-4 py-5 mx-4">
        <h2 className="text-center mt-5 fw-600 ">
          {data?.user?.firstName}{' '}
          {data?.user?.lastName}
        </h2>
        <h3 className="text-center mt-5 fw-600 ">
          You are about to delete this member permanently?
        </h3>
        <h5 className="text-center mb-5 fw-600 text--grey">
          Are you sure you want to delete this member permanently? This member
          won’t be able to access dashboard information
        </h5>
        <div className="d-flex">
          <button className="btn btn--danger px-4" onClick={mutate}>
            {isLoading ? <CustomSpinner /> : "Yes, Delete Access "}
          </button>
          <button
            className="btn btn--primary--bordered px-4 ms-4"
            onClick={toggle}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteTeamModal;
