import { getError, timeDateFormat, titleCase, toCurrency } from "utils/helpers";
import UserInfoPill from "utils/pills/UserInfoPill/UserInfoPill";
import SectionWrapper from "utils/section/SectionWrapper/SectionWrapper";
import Table from "utils/Table/Table";
import WalletLowerChat from "./charts/WalletLowerChat/WalletLowerChat";
import WalletUpperChat from "./charts/WalletUpperChat/WalletUpperChat";
import { useState } from "react";
import API from "api";
import { getRowSize } from "storage/sessionStorage";
import StatusPill from "utils/pills/StatusPill/StatusPill";
import { PENDING, SUCCESS } from "utils/helpers/constants";
import { useQuery } from "react-query";
import { getTransactionHistory } from "api/dashboard";
import BigSpinner from "utils/Spinner/BigSpinner";
import FailedState from "utils/Modal/Response/FailedState";
import { transactionsTableHeadings } from "../DashContent/data";
import TableFooter from "utils/Table/TableFooter/TableFooter";
import TransactionHistoryFilters from "../DashContent/filters/TransactionHistoryFilters";
import TransactionRecieptModal from "../Reports/modal/TransactionRecieptModal";

const Wallets = () => {
  const [filters, setFilters] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(() => getRowSize());

  const [currentRowValues, setCurrentRowValues] = useState({});
  const toggle = () => {
    setShowModal((prev) => !prev);
  };

  const handleFilters = (filter = {}) => {
    setFilters((prev) => ({ ...prev, ...filter }));
  };

  const clearFilters = (isDate) => {
    setFilters((prev) => ({}));
    setCurrentPage(0);
  };
  const handlePagination = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleLimitChange = (limit) => {
    setCurrentPage(0);
    setLimit(limit);
  };

  const getTransactionHistoryQuery = useQuery(
    ["totalTransactionHistory", filters, currentPage, limit],
    async () => {
      const response = await API.get(getTransactionHistory, {
        params: { page: currentPage, ...filters, limit },
      });
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const dataParser = (data) => {
    return data?.map((report) => {
      const { date, time } = timeDateFormat(report?.createdAt);
      return {
        name: <UserInfoPill name={report?.user} company={""} />,
        transId: report?.transactionId,
        amount: `${report?.currency} ${toCurrency(report?.amount)}`,
        balance: `${report?.currency} ${toCurrency(report?.balance)}`,
        category: titleCase(report?.category),
        status: (
          <StatusPill
            status={report?.status}
            success={SUCCESS}
            warning={PENDING}
          />
        ),
        createdAt: `${date} ${time}`,
      };
    });
  };

  const handleRowClick = (id, rowValues) => {
    const transactionData = getTransactionHistoryQuery?.data?.data?.find(
      (transaction) => transaction?.transactionId === rowValues?.transId
    );
    setCurrentRowValues({
      ...rowValues,
      ...transactionData,
    });
    toggle();
  };
  const renderByStatus = () => {
    const { isLoading, isError, error, data } = getTransactionHistoryQuery;
    if (isLoading)
      return (
        <div className="w-100 h-500 position-relative flex__column flex__center">
          <BigSpinner />
        </div>
      );
    if (isError)
      return (
        <div className="table__error">
          <FailedState
            onRetry={getTransactionHistoryQuery.refetch}
            title="Error Fetching Payment Transactions"
            errorMsg={getError(error)}
          />
        </div>
      );
    return (
      <>
        <Table
          title=""
          data={dataParser(data?.data)}
          headings={transactionsTableHeadings}
          rowClick={handleRowClick}
        />
        <TableFooter
          currentPage={currentPage || 0}
          totalPages={Math.ceil(data.total / 25)}
          handlePagination={handlePagination}
          handleLimitChange={handleLimitChange}
        />
      </>
    );
  };

  

  // const handleRowClick = (id, rowValues) => {
  //    history.push("/wallets/id");
  //   console.log("data", rowValues);
  // };
  return (
    <>
      <SectionWrapper routeName="Wallets">
        <WalletUpperChat />
        <WalletLowerChat />
        <div className="p-4 bg-white rounded-card">
          <TransactionHistoryFilters
            filters={filters}
            handleFilters={handleFilters}
            clearFilters={clearFilters}
            data={getTransactionHistoryQuery?.data?.data}
            handleLimitChange={handleLimitChange}
          />
          {renderByStatus()}

          {showModal && (
            <TransactionRecieptModal data={currentRowValues} toggle={toggle} />
          )}
        </div>
      </SectionWrapper>
    </>
  );
};

export default Wallets;
