import { useState } from "react";
import UserInfoPill from "utils/pills/UserInfoPill/UserInfoPill";
import SectionWrapper from "utils/section/SectionWrapper/SectionWrapper";
import Table from "utils/Table/Table";
import API from "api";
import { auditTrailTableHeadings, tabs } from "../data";
import AuditTrailFilters from "../filters/AuditTrailsFilters";
import AuditTrailDetailModal from "../modal/AuditTrailDetailModal";
import { getRowSize } from "storage/sessionStorage";
import { useQuery } from "react-query";
import { getAuditTrails } from "api/reports";
import { timeDateFormat } from "utils/helpers";
import FailedState from "utils/Modal/Response/FailedState";
import BigSpinner from "utils/Spinner/BigSpinner";
import TableFooter from "utils/Table/TableFooter/TableFooter";

const AuditTrail = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentRowValues] = useState({});
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(() => getRowSize());
  const toggle = () => {
    setShowModal((prev) => !prev);
  };

  const handleFilters = (filter = {}) => {
    setFilters((prev) => ({ ...prev, ...filter }));
  };

  const clearFilters = (isDate) => {
    setFilters((prev) => ({}));
    setCurrentPage(0);
  };
  const handlePagination = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleLimitChange = (limit) => {
    setCurrentPage(0);
    setLimit(limit);
  };

  const getAuditTrailQuery = useQuery(
    ["totalAuditTrail", filters, currentPage, limit],
    async () => {
      const response = await API.get(getAuditTrails, {
        params: {
          page: currentPage,
          ...filters,
          activityType: filters?.activityType?.value,
          limit,
        },
      });
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const dataParser = (data) => {
    return data?.map((report) => {
      const { date, time } = timeDateFormat(report?.createdAt);
      return {
        name: (
          <UserInfoPill name={`${report?.firstName} ${report?.lastName}`} />
        ),
        activityType: report?.activityType,
        description: report?.description,
        createdAt: `${date} ${time}`,
      };
    });
  };

  const renderByStatus = () => {
    const { isLoading, isError, error, data } = getAuditTrailQuery;
    if (isLoading)
      return (
        <div className="w-100 h-500 position-relative flex__column flex__center">
          <BigSpinner />
        </div>
      );
    if (isError)
      return (
        <div className="table__error">
          <FailedState
            onRetry={getAuditTrailQuery.refetch}
            title="Error Fetching Merchant Transactions"
            errorMsg={error?.data?.error}
          />
        </div>
      );
    return (
      <>
        <Table
          title=""
          data={dataParser(data?.data)}
          headings={auditTrailTableHeadings}
          rowClick={handleRowClick}
        />
        <TableFooter
          currentPage={currentPage || 0}
          totalPages={Math.ceil(data.total / 25)}
          handlePagination={handlePagination}
          handleLimitChange={handleLimitChange}
        />
      </>
    );
  };
  const handleRowClick = (id, rowValues) => {
    // setCurrentRowValues(auditTrailTableData[0]);
    // toggle();
  };
  return (
    <>
      <SectionWrapper routeName="Reports" tabs={tabs}>
        <div className="p-4 bg-white rounded-card">
          <AuditTrailFilters
            filters={filters}
            handleFilters={handleFilters}
            clearFilters={clearFilters}
            data={getAuditTrailQuery?.data?.data}
          />
          {renderByStatus()}
          {showModal && (
            <AuditTrailDetailModal data={currentRowValues} toggle={toggle} />
          )}
        </div>
      </SectionWrapper>
    </>
  );
};

export default AuditTrail;
