import { Link } from "react-router-dom";
import { NavlinkWrapper } from "./NavlinkWrapper";

const Navlink = ({ link, onClick }) => {
  return (
    <NavlinkWrapper
      isActive={
        window?.location?.pathname?.split("/")[1] === link?.link?.split("/")[1]
      }
    >
      {onClick ? (
        <span
          onClick={onClick}
        >
          <Link to='#'>
            {<link.icon />}
            <span>{link.name}</span>
          </Link>
        </span>
      ) : (
        <Link to={link.link}>
          {<link.icon />}
          <span>{link.name}</span>
        </Link>
      )}
    </NavlinkWrapper>
  );
};

export default Navlink;
