export const responseStatus = {
    IDLE: "IDLE",
    LOADING: "LOADING",
    EMPTY: "EMPTY",
    FAILED: "FAILED",
    SUCCESS: "SUCCESS",
};

export const LAYOUT = {
  xSm: "320px",
  sm: "420px",
  md: "768px",
  lg: "960px",
  xLg: "1024px",
  xxLg: "1280px",
};