import Iframe from "react-iframe";
import React, { createContext, useEffect, useState } from "react";
import API from "api";
import { getUserProfile } from "api/auth";
import BigSpinner from "utils/Spinner/BigSpinner";

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [checkoutData, setCheckoutData] = useState({});

  const fetchProfile = async () => {
    setIsLoading(true);
    try {
      const response = await API.get(getUserProfile);
      setUserDetails(response.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const refetch = async () => {
    const response = await API.get(getUserProfile);
    setUserDetails(response.data.data);
  };

  const { url } = checkoutData;

  // Check Checkout Event
  const receiveMessage = async (event) => {
    const { data: response } = event || {};
    const { name } = response || {};

    if (name === "close") setCheckoutData({});
    else if (name === "done") {
      // eslint-disable-next-line no-unused-expressions
      checkoutData.refetch && checkoutData.refetch();
      setCheckoutData({});
    }
    // }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("message", receiveMessage, false);
    }
    // eslint-disable-next-line
  });

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...userDetails,
        setCheckoutData,
        refetch,
      }}
    >
      {isLoading ? (
        <div className="w-100 h-500 position-relative flex__column flex__center">
          <BigSpinner />
        </div>
      ) : (
        children
      )}
      {url && <Iframe url={url} id="payment" />}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
