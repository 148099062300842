import { useState } from "react";
import CustomInputBox from "utils/inputBox/CustomInputBox/CustomInputBox";
import { CENTER, ERROR, SUCCESS } from "utils/helpers/constants";
import Modal from "utils/Modal/Modal";
import API from "api";
import CustomSelect from "utils/select/CustomSelect/CustomSelect";
import { ReactComponent as BigSuccess } from "assets/images/icons/big-success.svg";
import CustomPhoneNumber from "utils/inputBox/CustomPhoneNumber/CustomPhoneNumber";
import { formValidator } from "utils/FormValidator/FormValidator";
import { useMutation, useQuery } from "react-query";
import { addTeam, listRoles } from "api/settings";
import { toastHandler } from "utils/helpers/toast";
import CustomSpinner from "utils/CustomSpinner/CustomSpinner";

function InviteModal({ toggle, data, refetch }) {
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    memberRole: "",
    phoneNumber: "",
  });
  const handleSelect = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleChange = ({ target: { name, value } }) => {
    handleSelect(name, value);
  };
  const [step, setStep] = useState(data ? 1 : 0);

  const { mutate, isLoading } = useMutation(
    async () => {
      const payload = {
        ...formValues,
        memberRole: formValues.memberRole.value
      };
      delete payload.phoneCountryCode;
      delete payload.phoneCode;
      const response = await API.post(addTeam, payload);
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 201 && data?.success) {
          toastHandler(data?.message, SUCCESS);
          refetch()
          setStep(step + 1);
          toggle();
        } else toastHandler(data?.message, ERROR);
      },
      onError: (error) => {
        toastHandler(error?.data?.data?.error, ERROR);
      },
    }
  );
  function gotoNext(e) {
    e.preventDefault();
    const payload = {
      ...formValues,
    };
    const { formErrors, isValid } = formValidator(payload);
    setFormErrors(formErrors);
    isValid ? mutate() : setFormErrors(formErrors);
  }


  const getRolesQuery = useQuery(
    ["roles"],
    async () => {
      const response = await API.get(listRoles);
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  return (
    <Modal
      title={data ? "Change Role" : "Invite New Member"}
      subtitle={
        data ? "Change Role of team member" : "Invite people to join your team"
      }
      toggle={toggle}
      position={CENTER}
    >
      <div className="px-4 py-50 mx-4">
        {step === 0 && (
          <form onSubmit={gotoNext}>
            <CustomInputBox
              label="First Name"
              placeholder="Enter First Name"
              name="firstName"
              onChange={handleChange}
              value={formValues?.firstName}
              error={formErrors?.firstName}
            />
            <CustomInputBox
              label="Last Name"
              placeholder="Enter Last Name"
              name="lastName"
              onChange={handleChange}
              value={formValues?.lastName}
              error={formErrors?.lastName}
            />
            <CustomInputBox
              label="Email Address"
              placeholder="Enter Email Address"
              type="email"
              name="email"
              onChange={handleChange}
              value={formValues?.email}
              error={formErrors?.email}
            />
            <CustomSelect
              name="memberRole"
              label="Role"
              onChange={(value) => handleSelect("memberRole", value)}
              options={getRolesQuery?.data?.data?.map((bank) => ({
                label: bank.roleName,
                value: bank.id,
              }))}
              placeholder="Select Role"
              value={formValues?.memberRole}
              error={formErrors?.memberRole}
            />
            <CustomPhoneNumber
              label="Phone Number"
              dropdownID="phone-code-dropdown"
              dropDownName="phoneCode"
              name="phoneNumber"
              placeholder="Type Here"
              error={formErrors?.phoneNumber}
              onChange={handleChange}
              action={handleSelect}
              dropDownValue={formValues?.phoneCountryCode?.label}
              value={formValues?.phoneNumber}
            />
            <hr className="text--grey my-4" />
            <button className="btn btn--primary px-4">
              {" "}
              {isLoading ? <CustomSpinner /> : "Next"}
            </button>
          </form>
        )}
        {step === 1 && (
          <>
            <div className="flex__center">
              <BigSuccess />
            </div>
            <h3 className="text-center mt-5 fw-600 ">
              {data ? "Role Changed Successfully" : "Invite Sent"}
            </h3>
            <h5 className="text-center mb-5 fw-600 text--grey">
              {data
                ? "yemi@blusalt.net is now an admin"
                : "An invite has been sent to yemi@blusalt.net"}
            </h5>
            <button className="btn btn--primary px-4" onClick={toggle}>
              Close
            </button>
          </>
        )}
      </div>
    </Modal>
  );
}

export default InviteModal;
