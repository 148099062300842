import { FormControl, Select } from "@material-ui/core";

const SmallSelect = ({ name, value, formLabel, label, onChange, options }) => {
	return (
		<FormControl variant="outlined">
			{label && (
				<label className="mb-2 pb-1 fw-bold text--gray">{label}</label>
			)}
			<Select
				native
				value={value}
				onChange={onChange}
				label={formLabel}
				inputProps={{ name }}
			>
				{/* <option aria-label="None" value="" /> */}
				{options?.map((option) => (
					<option value={option} key={option}>
						{option}
					</option>
				))}
			</Select>
		</FormControl>
	);
};

export default SmallSelect;
