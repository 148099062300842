import { useEffect, useState } from "react";

import FilterWrapper from "utils/FilterWrapper/FilterWrapper";
import ExportAndFilter from "components/Reuseables/ExportAndFilter/ExportAndFilter";
import {
  commissionsReportHeadings,
} from "../data";

const CommissionsFilter = ({ data, filters, handleFilters, clearFilters }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [, setFormValues] = useState({});
  const [isApplied, setIsApplied] = useState(false);

  useEffect(() => {
    if (filters) {
      setFormValues(filters);
      setIsApplied(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearFilter = () => {
    setFormValues({});
    clearFilters();
    setIsApplied(false);
  };

  const handleSubmit = () => {
    handleFilters({});
    setIsApplied(true);
  };

  return (
    <div className="filters__container">
      <ExportAndFilter
        data={{
          data,
          filters,
          tableHeadings: commissionsReportHeadings,
          subTitle: "Download Commisions  Report",
        }}
        handleFilters={handleFilters}
        clearFilters={clearFilters}
        hideExportBtn={data?.total === 0}
        hideFilterBtn={true}
        onClick={() => setIsOpen((prev) => !prev)}
      />
      {isOpen && (
        <FilterWrapper
          handleFilter={handleSubmit}
          clearFilter={clearFilter}
          isApplied={isApplied}
          isFilter
        >
          {/* <div className="form-group">
            <label className="form-group-label mb-2">Filter Status</label>
            <div className="col-12">

            </div>
          </div> */}
        </FilterWrapper>
      )}
    </div>
  );
};

export default CommissionsFilter;
