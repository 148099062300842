import React from "react";
import ReactPaginate from "react-paginate";
import './Pagination.css'

const Pagination = ({ totalPages, paginate, ...rest }) => {
	const pageRange = 2;

	return (
		<div className="pagination-wrapper">
			<ReactPaginate
				previousLabel="Previous"
				nextLabel="Next"
				breakLabel="..."
				breakClassName="break-me"
				pageCount={totalPages}
				marginPagesDisplayed={pageRange}
				pageRangeDisplayed={pageRange}
				onPageChange={paginate}
				containerClassName="pagination"
				subContainerClassName="pages pagination"
				activeClassName="active"
				{...rest}
			/>
		</div>
	);
};

export default Pagination;
