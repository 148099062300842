import Modal from "utils/Modal/Modal";
import { CENTER } from "utils/helpers/constants";
import DownloadReportContent from "./DownloadReportContent";

const DownloadReportModal = ({ reportData, toggle }) => {
	return (
		<Modal
			subtitle={reportData.subTitle}
			title="Download Report"
			toggle={toggle}
			position={CENTER}
		>
			<DownloadReportContent reportData={reportData} toggle={toggle} />
		</Modal>
	);
};

export default DownloadReportModal;
