import { ReactComponent as ArrowDownIcon } from "assets/images/icons/arrow-down.svg";
import Dropdown from "utils/Dropdown/Dropdown";
import CustomInputBox from "../CustomInputBox/CustomInputBox";

const CustomInputBoxDropdown = ({
  name,
  label,
  labelIcon,
  placeholder,
  value,
  readonly,
  error,
  hide,
  icon,
  iconRight,
  infoText,
  inputLeft,
  onChange,
  onFocus,
  required,
  length,
  question,
  cls,
  disabled,
  action,
  secondary,
  dropdownID,
  dropDownValue,
  dropDownPlaceholder,
  options,
  ...rest
}) => (
  <CustomInputBox
    error={error}
    inputLeft={
      <CurrencyDropdown
        action={action}
        dropdownID={dropdownID}
        dropDownValue={dropDownValue}
        dropDownPlaceholder={dropDownPlaceholder}
        options={options}
      />
    }
    label={label}
    labelIcon={labelIcon}
    name={name}
    placeholder={placeholder}
    onChange={onChange}
    readonly={readonly}
    hide={hide}
    icon={icon}
    iconRight={iconRight}
    infoText={infoText}
    onFocus={onFocus}
    required={required}
    secondary={secondary}
    length={length}
    question={question}
    cls={cls}
    disabled={disabled}
    value={value}
    {...rest}
  />
);

const CurrencyDropdown = ({
  action,
  dropdownID,
  dropDownValue,
  dropDownPlaceholder,
  options,
}) => {
  const noDropdownValue = !dropDownValue ? " light-text-gray" : "";
  const value = !dropDownValue ? dropDownPlaceholder : dropDownValue;
  return (
    <div className="bg--faint-primary flex__center fw-normal input-left">
      <Dropdown
        action={action}
        id={dropdownID}
        icon={
          <div className="flex__between">
            <div className={`ps-2 heading-4${noDropdownValue}`}>{value}</div>
            <ArrowDownIcon className="ms-1 me-2 mb-1" />
          </div>
        }
        options={options}
      />
    </div>
  );
};

export default CustomInputBoxDropdown;
