import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import { TOKEN_KEY } from "utils/helpers/constants";

const PublicRoutes = ({ component: Component, ...rest }) => {
    const token = Cookies.get(TOKEN_KEY);

    return (
      <Route
        {...rest}
        render={(props) =>
          token ? <Redirect to="/dashboard" /> : <Component {...props} />
        }
      />
    );
};

export default withRouter(PublicRoutes);
