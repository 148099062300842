import { useContext, useState } from "react";
import CustomOTPInput from "utils/inputBox/CustomOTPInput/CustomOTPInput";
import { CENTER, ERROR, TOKEN_KEY } from "utils/helpers/constants";
import Modal from "utils/Modal/Modal";
import { useMutation } from "react-query";
import API from "api";
import { toastHandler } from "utils/helpers/toast";
import { verifyLogin2fa } from "api/auth";
import CustomSpinner from "utils/Spinner/Spinner";
import Cookies from "js-cookie";
import { AuthContext } from "utils/context/AuthContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function VerifyOTPModal({ toggle, title }) {
  const history = useHistory();
  const [OTP, setOTP] = useState();
  const { refetch } = useContext(AuthContext);
  function gotoNext() {
    mutate();
  }

  const { mutate, isLoading } = useMutation(
    async () => {
      const response = await API.post(verifyLogin2fa, {
        token: OTP,
      });
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 200 && data?.success) {
          const { token } = data?.data;
          Cookies.set(TOKEN_KEY, token, { expires: 1 });
          history.push("/dashboard");
          refetch();
        }
      },
      onError: (error) => {
        toastHandler("Unable to proceed", ERROR);
      },
    }
  );
  return (
    <Modal
      title={title}
      subtitle="Permission Request"
      toggle={toggle}
      position={CENTER}
    >
      <div className="px-4 py-50 mx-4">
        <>
          <h3 className="text-center mt-5 fw-600 ">Enter OTP</h3>
          <h5 className="text-center mb-5 fw-600 text--grey">
            Enter the 6-digit code sent to your Mobile Number
          </h5>
          <CustomOTPInput
            name="otp"
            onChange={(value) => setOTP(value)}
            value={OTP}
            separator="-"
          />
          <button className="btn btn--primary px-4" onClick={gotoNext}>
            {isLoading ? <CustomSpinner /> : "Next"}
          </button>
        </>
      </div>
    </Modal>
  );
}

export default VerifyOTPModal;
