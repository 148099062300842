import { useEffect, useState } from "react";

import FilterWrapper from "utils/FilterWrapper/FilterWrapper";
import ExportAndFilter from "components/Reuseables/ExportAndFilter/ExportAndFilter";
import { serviceReportsDownloadHeadings } from "../data";
import CustomSelect from "utils/select/CustomSelect/CustomSelect";
import CustomInputBox from "utils/inputBox/CustomInputBox/CustomInputBox";

const ServiceReportsFilters = ({
  data,
  filters,
  handleFilters,
  clearFilters,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    serviceType: "",
    service: "",
  });
  const [isApplied, setIsApplied] = useState(false);

  useEffect(() => {
    if (filters) {
      setFormValues(filters);
      setIsApplied(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleChange = ({ target: { name, value } }) => {
    handleSelect(name, value);
  };

  const clearFilter = () => {
    setFormValues({ serviceType: "", service:'' });
    clearFilters();
    setIsApplied(false);
  };

  const handleSubmit = () => {
    handleFilters({
      ...formValues,
    });
    setIsApplied(true);
  };

  return (
    <div className="filters__container">
      <ExportAndFilter
        data={{
          data,
          filters,
          tableHeadings: serviceReportsDownloadHeadings,
          subTitle: "Download Prices  Report",
        }}
        handleFilters={handleFilters}
        clearFilters={clearFilters}
        hideExportBtn={data?.total === 0}
        onClick={() => setIsOpen((prev) => !prev)}
      />
      {isOpen && (
        <FilterWrapper
          handleFilter={handleSubmit}
          clearFilter={clearFilter}
          isApplied={isApplied}
          isFilter
        >
          <div className="form-group">
            <div className="col-12">
              <CustomSelect
                name="serviceType"
                label="Service Type"
                onChange={(value) => handleSelect("serviceType", value)}
                options={[
                  { label: "Service", value: "Service" },
                  { label: "Bulk Service", value: "Bulk Service" },
                ]}
                placeholder="Select Service Type"
                value={formValues?.serviceType}
              />
              <CustomSelect
                name="service"
                label="Service"
                onChange={(value) => handleSelect("service", value)}
                options={[
                  { label: "Airtime", value: "Airtime" },
                  { label: "Data", value: "Data" },
                  { label: "SMS", value: "SMS" },
                  { label: "Bill Payment", value: "Bill Payment" },
                ]}
                placeholder="Select Service"
                value={formValues?.service}
              />
              <CustomInputBox
                label="Search"
                placeholder="Keyword"
                name="search"
                onChange={handleChange}
                value={formValues?.search}
              />
            </div>
          </div>
        </FilterWrapper>
      )}
    </div>
  );
};

export default ServiceReportsFilters;
