import { useState } from "react";
import { timeDateFormat, titleCase } from "utils/helpers";
import UserInfoPill from "utils/pills/UserInfoPill/UserInfoPill";
import SectionWrapper from "utils/section/SectionWrapper/SectionWrapper";
import Table from "utils/Table/Table";
import { serviceReportsTableHeadings, tabs } from "../data";
import ServiceReportsFilters from "../filters/ServiceReportFilters";
// import ServiceReportDetailModal from "../modal/ServiceReportDetailModal";
import { getRowSize } from "storage/sessionStorage";
import { useQuery } from "react-query";
import API from "api";
import { getServiceReports } from "api/reports";
import FailedState from "utils/Modal/Response/FailedState";
import BigSpinner from "utils/Spinner/BigSpinner";
import TableFooter from "utils/Table/TableFooter/TableFooter";

const ServiceReports = () => {
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(() => getRowSize());

  const handleFilters = (filter = {}) => {
    setFilters((prev) => ({ ...prev, ...filter }));
  };

  const clearFilters = (isDate) => {
    setFilters((prev) => ({}));
    setCurrentPage(0);
  };
  const handlePagination = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleLimitChange = (limit) => {
    setCurrentPage(0);
    setLimit(limit);
  };

  const getServiceReportsQuery = useQuery(
    ["totalTransactionHistory", filters, currentPage, limit],
    async () => {
      const response = await API.get(getServiceReports, {
        params: {
          page: currentPage,
          ...filters,
          serviceType: filters?.serviceType?.value,
          service: filters?.service?.value,
          limit,
        },
      });
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const dataParser = (data) => {
    return data?.map((report) => {
      const { date, time } = timeDateFormat(report?.createdAt);
      return {
        name: (
          <UserInfoPill name={report?.firstName} company={report?.company} />
        ),
        groupName: report?.groupName,
        serviceType: report?.serviceType,
        service: titleCase(report?.service),
        totalReceipients: report?.totalReceipients,
        totalDelivered: report?.totalDelivered,
        createdAt: `${date} ${time}`,
      };
    });
  };

  const renderByStatus = () => {
    const { isLoading, isError, error, data } = getServiceReportsQuery;
    if (isLoading)
      return (
        <div className="w-100 h-500 position-relative flex__column flex__center">
          <BigSpinner />
        </div>
      );
    if (isError)
      return (
        <div className="table__error">
          <FailedState
            onRetry={getServiceReportsQuery.refetch}
            title="Error Fetching Merchant Transactions"
            errorMsg={error?.data?.error}
          />
        </div>
      );
    return (
      <>
        <Table
          title=""
          data={dataParser(data?.data)}
          headings={serviceReportsTableHeadings}
          rowClick={handleRowClick}
        />
        <TableFooter
          currentPage={currentPage || 0}
          totalPages={Math.ceil(data.total / 25)}
          handlePagination={handlePagination}
          handleLimitChange={handleLimitChange}
        />
      </>
    );
  };
  const handleRowClick = (id, rowValues) => {
    // setCurrentRowValues();
    // toggle();
    console.log("data", rowValues);
  };
  return (
    <>
      <SectionWrapper routeName="Reports" tabs={tabs}>
        <div className="p-4 bg-white rounded-card">
          <ServiceReportsFilters
            filters={filters}
            handleFilters={handleFilters}
            clearFilters={clearFilters}
            data={getServiceReportsQuery?.data?.data}
          />
          {renderByStatus()}
          {/* {showModal && (
            <ServiceReportDetailModal data={currentRowValues} toggle={toggle} />
          )} */}
        </div>
      </SectionWrapper>
    </>
  );
};

export default ServiceReports;
