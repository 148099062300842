import styled from "styled-components"

export const UserInfoPillWrapper = styled.div`
   display: flex;
   div {
    margin-left: 10px;
     .name {
     }
     .company {
       font-weight: 500;
       font-size: 10px;
       line-height: 12px;
       color: var(--gray-2);
     }
   }
 `;