import axios from "axios";
import { amountCurrencyOptions, CENTER } from "utils/helpers/constants";
import Modal from "utils/Modal/Modal";
import CustomInputBoxDropdown from "utils/inputBox/CustomInputBoxDropdown/CustomInputBoxDropdown";
import { useContext, useState } from "react";
import API from "api";
import {  useQuery } from "react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getSingleMerchant } from "api/merchant";
import { toastHandler } from "utils/helpers/toast";
import { AuthContext } from "utils/context/AuthContext";

function FundWalletModal({ toggle, refetch }) {
  const { id } = useParams();
  const [formValues, setFormValues] = useState({
    amount: "",
    amountCurrencyOptions: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const handleChange = ({ target: { name, value } }) =>
    handleSelect(name, value);

  const handleSelect = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const { setCheckoutData } = useContext(AuthContext);

  const getMerchantDetailQuery = useQuery(
    ["merchantDetailHistory"],
    async () => {
      const response = await API.get(getSingleMerchant, {
        params: { merchantId: id },
      });
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const merchantDetail = getMerchantDetailQuery;

  const handleSubmit = async () => {
    const payload = {
      walletId: "master",
      amount: parseInt(formValues.amount, 10),
      currency: "NGN",
      metadata: {
        merchant_wallet_reference:
          merchantDetail?.data?.data?.user?.merchantOrganizationId,
      },
    };

    const url = `${process.env.REACT_APP_CHECKOUT_BASE_URL}/checkout/initialize`;

    const Authorization = process.env.REACT_APP_PAYMENT_API_KEY;
    const response = await axios.post(url, payload, {
      headers: { Authorization, "X-Api-Key": process.env.REACT_APP_X_API_KEY },
    });
    const { status, data } = response;

    if (status === 200) {
      setCheckoutData({
        url: data?.data?.authorization_url,
        refetch,
      });
      toggle();
    } else {
      toastHandler("Unable to initialize checkout");
    }
  };

  return (
    <Modal
      title="Fund Wallet"
      subtitle="Top-up merchant's wallet"
      toggle={toggle}
      position={CENTER}
    >
      <div className="px-4 py-50 mx-4">
        <form>
          <CustomInputBoxDropdown
            action={(value) =>
              handleSelect("amountCurrency", amountCurrencyOptions[value])
            }
            dropdownID="currency-dropdown"
            dropDownPlaceholder="NGN"
            dropDownValue={formValues?.amountCurrency}
            error={formErrors?.amount}
            label="Amount"
            name="amount"
            options={amountCurrencyOptions}
            placeholder="₦ 0.00"
            onChange={handleChange}
            value={formValues?.amount}
          />
          <hr className="text--grey my-4" />
          <button
            className="btn btn--primary px-4"
            type="button"
            disabled={formValues.amount < 1000}
            onClick={handleSubmit}
          >
            Fund
          </button>
        </form>
      </div>
    </Modal>
  );
}

export default FundWalletModal;
