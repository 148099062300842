import { useState } from "react";
import SectionWrapper from "utils/section/SectionWrapper/SectionWrapper";
import Table from "utils/Table/Table";
import API from "api";
import { teamTableHeadings, tabs, INVITE } from "../data";
import DeleteTeamModal from "../modals/DeleteTeamModal";
import TeamActionsModal from "../modals/TeamActionsModal/TeamActionsModal";
import { getRowSize } from "storage/sessionStorage";
import { getError, timeDateFormat} from "utils/helpers";
import { useQuery } from "react-query";
import { listTeams } from "api/settings";
import { TableFooter } from "@material-ui/core";
import FailedState from "utils/Modal/Response/FailedState";
import BigSpinner from "utils/Spinner/BigSpinner";
import TeamActionButtons from "../buttons/TeamActionButtons/TeamActionButtons";

const Team = () => {
  const [filters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(() => getRowSize());
  
  const handlePagination = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleLimitChange = (limit) => {
    setCurrentPage(0);
    setLimit(limit);
  };

  const getTransactionHistoryQuery = useQuery(
    ["team", filters, currentPage, limit],
    async () => {
      const response = await API.get(listTeams, {
        params: { page: currentPage, ...filters, limit },
      });
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const dataParser = (data) => {
    return data?.map((report) => {
      const { date, time } = timeDateFormat(report?.createdAt);
      return {
        email: report?.user?.email,
        name: `${report?.user?.firstName} ${report?.user?.lastName}`,
        role: report?.role?.roleName,
        createdAt: `${date} ${time}`,
        action: <TeamActionButtons data={report} setSelected={setSelected} />,
      };
    });
  };

  // const handleRowClick = (id, rowValues) => {
  //   console.log(rowValues);
  //   toggle();
  // };

   const renderByStatus = () => {
     const { isLoading, isError, error, data } = getTransactionHistoryQuery;
     if (isLoading)
       return (
         <div className="w-100 h-500 position-relative flex__column flex__center">
           <BigSpinner />
         </div>
       );
     if (isError)
       return (
         <div className="table__error">
           <FailedState
             onRetry={getTransactionHistoryQuery.refetch}
             title="Error Fetching Payment Transactions"
             errorMsg={getError(error)}
           />
         </div>
       );
     return (
       <>
         <Table
           title="All Team Members"
           data={dataParser(data?.data)}
           headings={teamTableHeadings}
           rowClick={()=>{}}
         />
         <TableFooter
           currentPage={currentPage || 0}
           totalPages={Math.ceil(data.total / 25)}
           handlePagination={handlePagination}
           handleLimitChange={handleLimitChange}
         />
       </>
     );
   };
  const [currentData, setCurrentData] = useState({});
  const [currentStatus, setCurrentStatus] = useState();
  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    setShowModal((prev) => !prev);
  };

  const setSelected = (selected, status) => {
    setCurrentData(selected || {});
    setCurrentStatus(status);
  };
  
  return (
    <>
      <SectionWrapper
        routeName="Team"
        tabs={tabs}
        headerChildren={
          <button
            className="btn btn--primary"
            onClick={() => setCurrentStatus(INVITE)}
          >
            Invite New Member
          </button>
        }
      >
        <div className="p-4 bg-white rounded-card">
          {/* <TeamFilter
            filters={filters}
            handleFilters={handleFilters}
            clearFilters={clearFilters}
            data={getTransactionHistoryQuery?.data?.data}
            handleLimitChange={handleLimitChange}
          /> */}
          {renderByStatus()}
          {showModal && <DeleteTeamModal toggle={toggle} />}
          {currentStatus && (
            <TeamActionsModal
              refetch={getTransactionHistoryQuery.refetch}
              data={currentData}
              status={currentStatus}
              toggle={setSelected}
            />
          )}
        </div>
      </SectionWrapper>
    </>
  );
};

export default Team;
