import { Tab } from "utils/NavTabs/NavTabs";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { UNDERLINED } from "utils/helpers/constants";
import {ReactComponent as ChevLeft} from 'assets/images/icons/chevron-right.svg'

const SectionNav = ({
  children,
  routeName,
  tabs,
  navTabChildren,
  navCls,
  navTabCls,
  tabType = UNDERLINED,
  cls,
  goBackRoute,
}) => {
  const history = useHistory();
  const path = window.location.pathname;

  return (
    <div className={`header px-0 flex-wrap mb-4 ${cls}`}>
      {(routeName || children) && (
        <div className="w-100 d-flex justify-content-between align-items-start flex-wrap">
          <div className="d-flex">
            {goBackRoute?.name && (
              <div
                className="text--md text--grey fw-bold mt-2 pointer"
                onClick={() => history.push(goBackRoute?.to)}
              >
                {goBackRoute?.name} <ChevLeft className="mx-3" />
              </div>
            )}
            <div className="text--lg fw-bold">{routeName}</div>
          </div>
          {children}
        </div>
      )}

      {tabs && (
        <div
          className={`flex__between w-100${
            tabType === UNDERLINED ? " underlined-tabs" : ""
          } ${navTabCls}`}
        >
          <div className={`nav-tabs-container ${navCls}`}>
            <ul className="nav nav-tabs overflow-x">
              {tabs?.map(({ label, route, routeAs }, i) => (
                <Tab
                  id={`route-${i}`}
                  active={path.startsWith(routeAs || route) ? "active" : ""}
                  tabName={label}
                  key={`route-${i}`}
                  handleClick={() => history.push(route, routeAs)}
                  type={tabType}
                />
              ))}
            </ul>
          </div>

          <div className={!navTabCls ? "ps-4" : ""}>{navTabChildren}</div>
        </div>
      )}
    </div>
  );
};

export default SectionNav;
