
import { BLOCK, CHANGE, RESEND, DELETE, INVITE } from "../../data";
import BlockTeamModal from "../BlockTeamModal";
import DeleteTeamModal from "../DeleteTeamModal";
import InviteModal from "../InviteModal";

const TeamActionsModal = ({ status, data, toggle, refetch }) => {
  const renderByStatus = () => {
    switch (status) {
      case BLOCK:
        return <BlockTeamModal data={data} toggle={toggle} />;

      case CHANGE:
        return <InviteModal data={data} toggle={toggle} refetch={refetch} />;

      case RESEND:
        return <InviteModal toggle={toggle} refetch={refetch} />;

      case INVITE:
        return <InviteModal toggle={toggle} refetch={refetch} />;

      case DELETE:
        return (
          <DeleteTeamModal data={data} toggle={toggle} refetch={refetch} />
        );

      default:
        return "";
    }
  };
  return <>{renderByStatus()}</>;
};

export default TeamActionsModal;
