import SectionWrapper from "utils/section/SectionWrapper/SectionWrapper";
import { ReactComponent as FineBoyAvatar } from "assets/images/icons/fine-boy-lg.svg";
import { tabs } from "../data";
import CustomInputBox from "utils/inputBox/CustomInputBox/CustomInputBox";
import CustomPhoneNumber from "utils/inputBox/CustomPhoneNumber/CustomPhoneNumber";
import CustomSelect from "utils/select/CustomSelect/CustomSelect";
import CountrySelect from "utils/select/CountrySelect/CountrySelect";
import StateSelect from "utils/select/StateSelect/StateSelect";
import { useContext, useState } from "react";
import API from "api";
import { AuthContext } from "utils/context/AuthContext";
import { Country, State } from "country-state-city";
import CustomSpinner from "utils/Spinner/Spinner";
import { useMutation } from "react-query";
import { toastHandler } from "utils/helpers/toast";
import { ERROR, SUCCESS } from "utils/helpers/constants";
import { updateUserProfile } from "api/settings";
import { formValidator } from "utils/FormValidator/FormValidator";

const Settings = () => {
  const [formErrors, setFormErrors] = useState({});

  const userProfile = useContext(AuthContext);

  const handleChange = ({ target: { name, value } }) => {
    handleSelect(name, value);
  };

  const handleSelect = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));

    if (name === "country") {
      const phoneCode = `${value.phonecode?.startsWith("+") ? "" : "+"}${
        value?.phonecode
      }`;
      setFormValues((prev) => ({
        ...prev,
        state: null,
        phoneCountryCode: {
          ...value,
          label: `${value?.flag} (${phoneCode})`,
        },
      }));
    }

    if (name === "state") {
      setFormValues((prev) => ({
        ...prev,
      }));
    }
  };
  const country = Country.getAllCountries().find(
    (country) => country?.name === userProfile?.country
  );
  const getState = () => {
    const state = State.getStatesOfCountry(country?.isoCode).find(
      (country) => country?.name === userProfile?.state
    );

    if (state) {
      state.label = state?.name;
      state.value = state?.isoCode;
    }

    return state;
  };

  const [formValues, setFormValues] = useState({
    ...userProfile.user,
    phoneNumber: userProfile?.user?.phoneNumber.split(" ")[1],
    companyAddress: userProfile?.companyAddress,
    companyAddress_2: userProfile?.companyAddress_2,
    zipCode: userProfile?.zipCode,
    city: userProfile?.city,
    businessType: {
      value: userProfile?.businessType,
      label: userProfile?.businessType,
    },
    state: getState(),
    country: { ...country, label: country?.name, value: country?.isoCode },
  });

  const { mutate, isLoading } = useMutation(
    async () => {
      const payload = {
        businessType: formValues.businessType.value,
        companyAddress: formValues.companyAddress,
        companyAddress_2: formValues.companyAddress_2,
        city: formValues.city,
        zipCode: formValues.zipCode,
        state: formValues.state.name,
        country: formValues.country.name,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
      };
      const response = await API.put(updateUserProfile, payload);
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 200 && data?.success) {
          userProfile.refetch();
          toastHandler("Profile Updated Successfuly", SUCCESS);
        } else {
          toastHandler(data?.data?.success, ERROR);
        }
      },
      onError: (error) => {
        toastHandler(error?.data?.data?.error, ERROR);
      },
    }
  );

  function handleSubmit(e) {
    e.preventDefault();
    const payload = {
      businessType: formValues.businessType.value,
      companyAddress: formValues.companyAddress,
      zipCode: formValues.zipCode,
      city: formValues.city,
      state: formValues.state?.name,
      country: formValues.country.name,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
    };
    const { formErrors, isValid } = formValidator(payload);
    isValid ? mutate() : setFormErrors(formErrors);
  }
  return (
    <>
      <SectionWrapper routeName="Settings" tabs={tabs}>
        <div className="p-5 bg-white rounded-card">
          <h4 className="text--grey fw-600">Representative Information</h4>
          <hr className="text--grey" />
          <div className="flex__center py-5">
            <FineBoyAvatar />
          </div>

          <form action="">
            <div className="row mb-5">
              <div className="col-sm-5">
                <CustomInputBox
                  name="firstName"
                  label="First Name"
                  placeholder="Enter First Name"
                  error={formErrors?.firstName}
                  value={formValues.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-5">
                <CustomInputBox
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter Last Name"
                  value={formValues.lastName}
                  error={formErrors?.lastName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-5">
                <CustomInputBox
                  label="Email Address"
                  placeholder="Enter Email Address"
                  value={formValues.email}
                  disabled
                />
              </div>
              <div className="col-sm-5">
                <CustomPhoneNumber
                  label="Phone Number"
                  dropdownID="phone-code-dropdown"
                  dropDownName="phoneCode"
                  name="phoneNumber"
                  placeholder="Type Here"
                  action={() => {}}
                  dropDownValue={formValues?.phoneCountryCode?.label}
                  value={formValues.phoneNumber}
                  disabled
                />
              </div>
            </div>
            <h4 className="text--grey fw-600">Company Information</h4>
            <hr className="text--grey mb-5" />
            <div className="row">
              <div className="col-sm-5">
                <CustomInputBox
                  name="businessName"
                  label="Company Name"
                  placeholder="Enter Company Name"
                  value={formValues.businessName}
                  error={formErrors?.businessName}
                  disabled
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-5">
                <CustomInputBox
                  name="companyAddress"
                  label="Company Address"
                  placeholder="Enter Company Address"
                  value={formValues.companyAddress}
                  error={formErrors?.companyAddress}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-5">
                <CustomInputBox
                  name="companyAddress_2"
                  label="Company Address 2"
                  placeholder="Enter Company Address 2"
                  value={formValues.companyAddress_2}
                  error={formErrors?.companyAddress_2}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-5">
                <CustomInputBox
                  name="city"
                  label="City"
                  placeholder="City"
                  value={formValues.city}
                  error={formErrors?.city}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-5">
                <CustomInputBox
                  name="zipCode"
                  label="Zip Code"
                  placeholder="Zip Code"
                  value={formValues.zipCode}
                  error={formErrors?.zipCode}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-5">
                <CustomSelect
                  name="businessType"
                  label="Sector"
                  onChange={(value) => handleSelect("businessType", value)}
                  options={[
                    { label: "Agriculture", value: "Agriculture" },
                    { label: "Health", value: "Health" },
                    { label: "Technology", value: "Technology" },
                  ]}
                  placeholder="Select Sector"
                  value={formValues.businessType}
                />
              </div>
              <div className="col-sm-5">
                <CountrySelect
                  label="Country"
                  placeholder="Select Country"
                  value={formValues.country}
                  onChange={(value) => handleSelect("country", value)}
                  error={formErrors?.country}
                />
              </div>
              <div className="col-sm-5">
                <StateSelect
                  label="State"
                  placeholder="Select State"
                  countryCode={formValues?.country?.isoCode}
                  value={formValues.state}
                  error={formErrors?.state}
                  onChange={(value) => handleSelect("state", value)}
                />
              </div>
            </div>
            <div className="">
              <button className="btn btn--primary px-4" onClick={handleSubmit}>
                {isLoading ? <CustomSpinner /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
        {/* {showModal && <OTPModal title="Edit Profile" toggle={toggle} />} */}
      </SectionWrapper>
    </>
  );
};

export default Settings;
