import React, { useEffect } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import PublicRoutes from "../RoutesWrapper/PublicRoutes";
import Login from "components/Authentication/Login/Login";
import Dashboard from "components/Dashboard/Dashboard";
import { QueryClient, QueryClientProvider } from "react-query";
import PrivateRoutes from "components/RoutesWrapper/PrivateRoutes";
import { AuthProvider } from "utils/context/AuthContext";
import ForgotPassword from "components/Authentication/Login/ForgotPassword";
import ResetPassword from "components/Authentication/Login/ResetPassword";

const App = () => {
  // update theme
  useEffect(() => {
    // document.documentElement.style.setProperty(
    //   "--primary",
    //   `rgba(38, 38, 115,1)`
    // );
    // document.documentElement.style.setProperty(
    //   "--secondary",
    //   `rgba(${204}, 0, ${204}, 0.7)`
    // );
    // document.documentElement.style.setProperty(
    //   "--secondary-accent-alpha",
    //   `rgba(${204}, 0, 204, 0.1)`
    // );
    // document.documentElement.style.setProperty(
    //   "--secondary-accent-beta",
    //   `rgba(204, 0, 204, 0.7)`
    // );
    // document.documentElement.style.setProperty(
    //   "--secondary-accent-gamma",
    //   `rgba(${204}, 0, 204, 0.1)`
    // );
    // document.documentElement.style.setProperty(
    //   "--secondary-accent-theta",
    //   `rgba(204, 0, 204, 0.05)`
    // );
    // document.documentElement.style.setProperty(
    //   "--secondary-accent-zeta",
    //   `rgba(204, 0, 204, 0.05)`
    // );
    // rebuild
  }, []);

  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BrowserRouter>
          <Switch>
            <PublicRoutes path="/login" component={Login} />
            <PublicRoutes path="/forgot-password" component={ForgotPassword} />
            <PublicRoutes path="/auth/reset-password/:id" component={ResetPassword} />

            <PrivateRoutes path="/" component={Dashboard} />
          </Switch>
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
