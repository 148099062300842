import styled from "styled-components";

export const SideNavWrapper = styled.div`
  ul {
    width: 100%;
    list-style-type: none;
    padding: 0;
    transition: all 0.3s linear;
  }
  @media screen and (max-width: 590px) {
  }
`;