import DateFilters from "utils/FilterWrapper/DateFilters/DateFilters";
import DownloadReport from "utils/DownloadReport/DownloadReport";
import { ReactComponent as FilterIcon } from "assets/images/icons/filter.svg";

const ExportAndFilter = ({
	data,
	hideExportBtn,
	hideFilterBtn,
	handleFilters,
	clearFilters,
	children,
	onClick,
}) => {
	return (
		<div className="flex__between bg--faint-gray flex-wrap px-3 py-3 my-3">
			<DateFilters
				filters={data?.filters}
				handleFilters={handleFilters}
				clearFilters={clearFilters}
			/>

			<div className="d-flex justify-content-sm-end flex-wrap">
				{children}

				{!hideFilterBtn && (
					<div className="me-4">
						<button
							className="btn btn--sm btn--light-gray--bordered"
							onClick={onClick}
						>
							<FilterIcon className="me-0 me-lg-2" />
							<span className="d-none d-lg-block">Filter</span>
						</button>
					</div>
				)}

				{!hideExportBtn && <DownloadReport data={data} />}
			</div>
		</div>
	);
};

export default ExportAndFilter;
