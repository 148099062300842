import { useRef } from "react";

const CustomCheckBox = ({ label, checked, name, secondary, onClick, labelCls, cls }) => {
	const checkboxEl = useRef(null)
	const isSecondary = secondary ? " secondary" : "";

	const handleClick = () => { checkboxEl.current.click()}

	return (
		<label className={`custom-check-box${isSecondary} ${cls}`}>
			<input
				type="checkbox"
				ref={checkboxEl}
				className="pointer"
				name={name}
				checked={checked}
				onChange={() => {}}
				onClick={onClick}
			/>
			{label && <label onClick={handleClick} htmlFor={name} className={labelCls}>{label}</label>}
		</label>
	);
};

export default CustomCheckBox;
