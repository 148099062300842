import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as InfoCircleGray } from "assets/images/icons/info-circle-gray.svg";
import { ReactComponent as Eye } from "assets/images/icons/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/icons/eye-slash.svg";
import { ReactComponent as Copy } from "assets/images/icons/copy.svg";
import { useState } from "react";

function WalletDetailsRIghtChart() {
  const [show, setShow] = useState(false);
  return (
    <div className="col-md-6 col-lg-6 p-4 pl-0">
      <div className="bg--primary px-0 rounded-card mb-4">
        <div className="flex__between p-5 flex-wrap">
          <div>
            <p className="text--grey-2 heading-4 mt-2 pb-2">
              Total Commissions{" "}
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-right">
                    Total Amount of money that was paid into your wallet. This
                    is the sum of all payments plus charges
                  </Tooltip>
                }
              >
                <span className="d-inline-block">
                  <InfoCircleGray className="ms-2 pointer" />
                </span>
              </OverlayTrigger>
            </p>
            <div className="d-flex">
              <p
                className={
                  show
                    ? "text--xl text-break  text--grey-2"
                    : "text--xl text-break  blur"
                }
              >
                N1,243,435
              </p>
              <div className="mt-2 ms-4 pointer" onClick={() => setShow(!show)}>
                {show ? <Eye /> : <EyeOff />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-card px-5">
        <div className="flex__between m-0">
          <p className="text--grey m-0">Partner Wallet Information</p>
          <span className="copy-div bg-secondary-accent-theta px-3 py-2 m-0 pointer">
            Copy Details <Copy />
          </span>
        </div>
        <hr className="text--grey mb-3" />
        <div className="row fw-600">
          <>
            <div className="col-4 text--grey">Bank Name</div>
            <div className="col-8 mb-3">Kuda Bank</div>
          </>
          <>
            <div className="col-4 text--grey">Bank Account Name</div>
            <div className="col-8 mb-3">Blusalt</div>
          </>
          <>
            <div className="col-4 text--grey">Bank Account Number</div>
            <div className="col-8 mb-1">2526738292627</div>
          </>
        </div>
      </div>
    </div>
  );
}

export default WalletDetailsRIghtChart;
